import * as XLSX from 'xlsx';

const downloadAsExcel = ({excel, nameFile="file", date=undefined}) => {
    if(excel.length === 0) return;
    const myFile = `${nameFile}${(date != undefined ? `_${date}` : "")}.xlsx`;
    const myWorkSheet = XLSX.utils.json_to_sheet(excel);
    const myWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(myWorkBook, myWorkSheet, "Hoja1");
    XLSX.writeFile(myWorkBook, myFile);
};

export default{
    downloadAsExcel,
    
}