 import React from 'react'
 
 export default function AboutMe() {
   return (
    <section className='mt-8'>

<div className="flex justify-center">
            <img
              alt=""
              src="https://nicolexv.pilcrow.mx/assets/images/sec-title-flower.png"
              className="w-32"
            />
          </div>


        <div className="text-center">
          <h2 className="text-2xl uppercase font-semibold raleway tracking-tight text-gray-900 sm:text-4xl">Acerca de mí</h2>
          {/* <p className="sm:text-lg text-md text-center  text-gray-500 ">For</p>  */}

        </div>
      <div className="flex sm:flex-row flex-col mx-auto w-3/4 mt-6 gap-8">

    
        <div className="flex justify-center">
            <img
              alt=""
              src="https://res.cloudinary.com/dxweypnlj/image/upload/v1720585171/DSC_3147_bca751cecb.png"
              className="w-full rounded-md"
            />
          </div>

          <div className="relative ">
            <blockquote className="text-lg text-gray-900 sm:text-xl ">
              <p>
              Me gusta mucho leer libros de fantasía mis favoritos son All the Young Dudes trilogía, también amo viajar mi lugar favorito es Universal Studios en Orlando Florida.
              </p>

            
            </blockquote>
          </div>
          
          
    
      </div>
    </section>
   )
 }
 

