import React from 'react'
import { Link } from 'react-router-dom'

export default function Cta() {
  return (
    <div className="bg-stone-100">
      <div className="mx-auto font-title max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl  tracking-tight text-cyan-800 sm:text-4xl">
          <span className="block">Crea tu <span className='font-spark text-4xl'>
                invitación
                </span></span>
          <span className="block">y controla tu lista de invitados.</span>
        </h2>
        <p className="mt-4 text-lg font-body font-light leading-6 text-gray-600">
          Una invitación que es más que diseño , ten el control de tus invitados teniendo los confirmados, enviando invitaciones con nombre de invitado o por familias y compartiendo la información con tu wedding planner o pareja.
        </p>
        <Link to={"/createInvite/stepOne"}>
        <p
          className="mt-8 font-body font-semibold inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-300 px-5 py-3 text-base  text-cyan-800 hover:bg-orange-400 sm:w-auto"
        >
          Crear tu invitación
        </p>
                </Link>
        
      </div>
    </div>
  )
}
