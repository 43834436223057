import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'



export default function MicroeventsSweet({events , placesPhotos}) {

    require('dayjs/locale/es')

dayjs.locale('es')

  const [eventsLocal , setEvents] = useState(events)
  const eventName = ["Boda Religiosa","Despedida de soltera","Recepción","Misa"]
  // var localizedFormat = require('dayjs/plugin/localizedFormat')
  // dayjs.extend(localizedFormat)

  
  useEffect(()=>{

    const newArr = events.map(event=>{

        if(eventName.includes(event.title)){
        let img = placesPhotos.filter(e=> e.name == event.title)
        return {...event, image: img.length ? img[0].image : undefined}
      }else{
        return event
      }
    })

    setEvents(newArr)

    

  },[placesPhotos])

  return (
    <div className='bg-white'>

        <div className="flex justify-center">
            <img
              alt=""
              src="https://nicolexv.pilcrow.mx/assets/images/sec-title-flower.png"
              className="w-32"
            />
          </div>
          <div className="mx-auto max-w-2xl px-4  sm:px-6 lg:max-w-7xl lg:px-8">

          <div className="text-center">
          <h2 className="text-2xl uppercase font-semibold raleway tracking-tight text-gray-900 sm:text-4xl">Te esperamos en:</h2>
          {/* <p className="sm:text-lg text-md text-center  text-gray-500 ">For</p>  */}

        </div>
        </div>

        
    <div className="z-10 font-raleway relative px-4 pt-6 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
      <div className="absolute inset-0">
        <div className="h-1/3  sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl">
        
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-2">
          {eventsLocal.map((event, i) => (
            <div key={event.place} className="flex flex-col text-center overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
              {
                event.image ? 
                 
                <img className="sm:h-64 h-56 w-full object-cover" src={i == 0 ? "https://res.cloudinary.com/dxweypnlj/image/upload/v1720673337/ivn0205_1024x682_c9aba56337.jpg" :event.image} alt="" />
                :
                <img className="sm:h-64 h-56 w-full object-cover" src="https://htmlcolorcodes.com/assets/images/colors/light-gray-color-solid-background-1920x1080.png" alt="" />
                
              }
              </div>
              <div className="flex flex-1 flex-col justify-center bg-white p-6">
                <div className="flex-1">
                  
                    <p className="text-xl font font-semibold text-gray-900 uppercase">{i == 0 ? "Misa" :event.title}</p>
                    <p className="text-lg font-medium text-gray-800"> {event.place}</p>
                    <p className="text-lg font font-semibold text-gray-900 uppercase">{dayjs(event.dateTime).format('LL')} </p>
                    <p className="text-lg font font-semibold text-gray-900 uppercase">{dayjs(event.dateTime).format('LT')}</p>
                    <p className="mt-3 text-base text-gray-500">Dirección : {event.address}</p>
                    {
                      event.googleLink ? 
                      <a href={event.googleLink} target="_blank" rel="noreferrer">
                      <p className="mt-3 text-base text-blue-500">ver mapa</p>
                      </a>
                      : 
                      null
                    }
                </div>
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  )
}



