import React from 'react'

export default function Header() {
  return (
    <div>
      
      <div className="flex justify-center">
            <img
              alt=""
              src="https://res.cloudinary.com/dxweypnlj/image/upload/v1720671533/danna_f123de9ab4.jpg?updated_at=2024-07-11T04:18:53.644Z"
              className="w-64"
            />
          </div>
    </div>
  )
}
