import React from 'react'
import { Link } from 'react-router-dom'

export default function Helpy() {
  return (
    <div className="relative white">
  <div className="h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
    <img className="w-full h-full object-cover" src="https://images.unsplash.com/photo-1519741497674-611481863552?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80" alt=""/>
  </div>
  <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <div className="md:ml-auto md:w-1/2 md:pl-10">
      
    <div className=" font-title max-w-2xl  text-left ">
    <h2 className="text-3xl  tracking-tight text-cyan-800 sm:text-4xl">
      <span className="block">Estamos para  <span className='font-spark text-4xl'>
      ayudarte
            </span></span>
      <span className="block"></span>
    </h2>
    <p className="mt-4 text-lg font-body font-light leading-6 text-gray-600">
          Todas las ideas son bienvenidas
        </p>
    
    
  </div>


      <div className="mt-8">

      <div className=''>
      <Link to={"/createInvite/stepOne"}>
      <p
      className="mt-8 font-body font-semibold inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-300 px-5 py-3 text-base  text-cyan-800 hover:bg-orange-400 sm:w-auto"
      >
      Crear tu invitación
      </p>
      </Link>
      </div>


      </div>
    </div>
  </div>
</div>
  )
}
