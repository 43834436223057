import { useEffect, useReducer } from "react";
import Context from "./Context";
import Reducer from "./Reducer";
import { ADD_GIFTS, ADD_MICROEVENTS, ADD_PHOTOS, ADD_PHOTOS_MICROEVENTS, LOGOUT, REFETCH, SAVE_PROGRESS, SET_GUEST_LIST, SET_ID, SET_MEMBERSHIP, SET_TOTAL_CONFIRMED, SET_USER } from "./types";
import { useLazyQuery } from "@apollo/client";
import { GET_ME } from "../GraphQL/Queries";
import { getToken, removeToken } from "../helpers";

const State = (props) =>{

    const initialState = {
        logged : false,
        user: null,
        step: 1,
        total:0,
        confirmed:0,
        refetchLo: false,
        membership:"free",
        eventName:null,
        idEvento: null, 
        progress: null,
        microevent:[],
        guestList:[{
            id:null , 
            attributes : {
            nameInvitation: null,
            confirmed: null,
            maxGuests:null,
            status:null,
            opened:null
            }
        }
      ],
        gifts:[
            {type: 'cuentabancaria', checked: false},
            {type: 'mesaderegalos', checked: false},
            {type: 'sobre', checked: false}
        ],
        photos:[
            {type: 'mainPhoto' , photos: null , blob: [] , max : 3},
            {type: 'Galery' , photos:null , blob: [] , max : 6},
            {type: 'Thanks' , photos: null , blob: [], max : 1},
            {type: 'countdown' , photos: null , blob: [], max : 1}
        ],
        placePhotos:[
            {type: 'religiousPhoto' , photos: null , blob: [] , max : 1},
            {type: 'bacheloretesPhoto' , photos:null , blob: [] , max : 1},
            {type: 'venuePhoto' , photos: null , blob: [], max : 1},
        ]
    }

    const [state , dispatch] = useReducer(Reducer , initialState)
    const [getMe] = useLazyQuery(GET_ME)
    const token = getToken();

      const loadUserData = async () => {
      
        const tnk = await getToken();

        console.log(tnk)
        if(!tnk) return;

        try{
                const dataUser = await getMe()
                if(dataUser.data){
                    setUser({
                        userId: Number(dataUser.data.me.id),
                        userName: dataUser.data.me.username,
                        eventName: dataUser.data.me.events.data[0].attributes.eventName,
                        idEvento: Number(dataUser.data.me.events.data[0].id),
                        membership: dataUser.data.me.events.data[0].attributes.type,
                        logged: true
                    });
                }else if(dataUser.error){
                    console.log(dataUser.error)

                    JSON.stringify(dataUser.error).includes("401") && removeToken()
                    // console.log(res)
                }

        }catch(e){
            console.log("SION TOKEN", e)
        }
        
        
      }
    const setUser = (data) =>{
        dispatch({
            type: SET_USER,
            payload: data
        })
    }

    const logOut = () =>{
        dispatch({
            type: LOGOUT
        })
        removeToken()
    }

    const setMembership = (data) =>{
        dispatch({
            type: SET_MEMBERSHIP,
            payload: data
        })
    }


    const saveProgress = (data) =>{
        dispatch({
            type: SAVE_PROGRESS,
            payload: data
        })
    }
    const setGifts = (data) =>{
        const filterNew = state.gifts.filter(event => event.type !== data.type)

        dispatch({
            type: ADD_GIFTS,
            payload: [...filterNew , data]
        })
    }
    const addMicroEvent = (data) =>{
        const filterNew = state.microevent.filter(event => event.type !== data.type)

        dispatch({
            type: ADD_MICROEVENTS,
            payload: [...filterNew, data]
        })
    }

    const addPhotosContext = (data) =>{
        const filterNew = state.photos.filter(photos => photos.type !== data.type)
        dispatch({
            type: ADD_PHOTOS,
            payload: [...filterNew, data]
        })
    }

    const addPhotosContextPlaces = (data) =>{
        const filterNew = state.placePhotos.filter(photos => photos.type !== data.type)
        dispatch({
            type: ADD_PHOTOS_MICROEVENTS,
            payload: [...filterNew, data]
        })
    }

    const setIdEvent = (data) =>{
        dispatch({
            type: SET_ID,
            payload: data
        })
    }

    

    const setGuestsList = (data) =>{
        dispatch({
            type: SET_GUEST_LIST,
            payload: data
        })
    }

    const setConfirmedAndTotal = (data)=>{
        dispatch({
            type:SET_TOTAL_CONFIRMED,
            payload:data
        })
    }

    const setRefetchLo = (data) =>{
        dispatch({
            type : REFETCH,
            payload: data
        })
    }

    useEffect(() => {
        if(token){
          loadUserData()
        }else{
          logOut()
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [token])

    return(
        <Context.Provider value={{
            logged : state.logged,
            step : state.step,
            idEvento : state.idEvento, 
            progress : state.progress,
            microevent: state.microevent,
            gifts:state.gifts,
            photos:state.photos,
            membership:state.membership,
            guestList:state.guestList,
            total:state.total,
            confirmed:state.confirmed,
            eventName: state.eventName,
            placePhotos: state.placePhotos,
            refetchLo: state.refetchLo,
            setRefetchLo,
            saveProgress,
            setIdEvent,
            addMicroEvent,
            setGifts,
            addPhotosContext,
            setMembership,
            setGuestsList,
            setConfirmedAndTotal,
            setUser,
            logOut,
            addPhotosContextPlaces,
            loadUserData

        }}>
            {props.children}
        </Context.Provider>
    )

}


export default State