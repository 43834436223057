import { Fragment, useContext, useEffect } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import Context from '../Context/Context'

  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

export default function Navbar() {

  const {logged , logOut} = useContext(Context)
  const { pathname } = useLocation();

 const navigation = useNavigate()
 const logoutLocal = ()=>{
  navigation("/")
  logOut()
 }


  return (
    <div className='z-40'>
    
      <Disclosure as="nav" className="bg-cyan-800">
        {({ open }) => (
          <div>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 z-50 ">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
        
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                {
                  pathname == "/user/dashboard" ? 

                  null
                  : 

                  <div className="flex flex-shrink-0 items-center">
                  <Link to="/">
  
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="https://res.cloudinary.com/nein/image/upload/v1704326477/invitarme-21_ecgrl2.png"
                      alt="Your Company"
                    />
                  </Link>
                  <Link to="/">
  
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="https://res.cloudinary.com/nein/image/upload/v1704326477/invitarme-21_ecgrl2.png"
                      alt="Your Company"
                    />
                  </Link>
                  </div>
                }
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                 
  
                  {/* Profile dropdown */}

                  {
                    logged &&
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className=" flex z-40 p-2 rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-6 w-6 rounded-full"
                          src="https://upload.wikimedia.org/wikipedia/commons/3/35/Emoji_u2665.svg"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <span
                            onClick={()=>navigation("/user/dashboard")}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700 ')}
                            >
                              Dashboard
                            </span>
                          )}
                        </Menu.Item>
                        
                        <Menu.Item >
                          {({ active }) => (
                            <span
                            onClick={()=>logoutLocal()}
                              className={classNames(active ? 'bg-gray-100 cursor-pointer' : '', 'cursor-pointer block px-4 py-2 text-sm text-gray-700')}
                            >
                              Cerrar sesión
                            </span>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                          }

                          {
                            (!logged && pathname != "/user/login")&&
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button
                              onClick={()=>navigation("/user/login")}
                              className=" flex z-40 rounded-md bg-orange-300 text-sm focus:outline-none focus:ring-2 focus:ring-white  focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className=' font-semibold text-cyan-900 px-3 py-1'>Iniciar sesión</span>
                              </Menu.Button>
                            </div>
                          
                          </Menu>
                                  }
                </div>
              </div>
            </div>
  
           
          </div>
        )}
      </Disclosure>
    
    </div>
  )
}
