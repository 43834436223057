import { useMutation } from '@apollo/client'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Context from '../../Context/Context'
import { UPDATE_EVENT_DATES } from '../../GraphQL/Mutations'
import GiftTable from '../microComponents/GiftTable'
import MicroEventsComponent from '../microComponents/MicroEventsComponent'

export default function FormEventDetails({placePhoto}) {

  const navigate = useNavigate()
  const {microevent , gifts , idEvento}  = useContext(Context)
  const [updateMicroEvent] = useMutation(UPDATE_EVENT_DATES , {
    onCompleted : ()=> navigate("/createInvite/stepThree")
  })
  
  const checkbox = gifts.filter(gift => gift.checked === true)
  const checked = gifts.filter(gift => gift.type === "sobre")
  const bankDetails = gifts.filter(gift => gift.type === "cuentabancaria" && gift.checked === true)
  const giftsDetails = gifts.filter(gift => gift.type === "mesaderegalos" && gift.checked === true)

  const microeventArranged = microevent.map((event)=>{
    return{
      title: event.type,
      dateTime: event.dateTime,
      place:event.placeName,
      address:event.placeAddress,
      googleLink: event.googleLink
    }
  })

  const handleStepTwo = (ev) =>{
    ev.preventDefault()

    if(microevent.length === 0){
      alert("Ingresa al menos un evento")
    }else if(checkbox.length === 0){
      alert("Ingresa una forma de regalo")
    }else{
      updateMicroEvent({variables:{
        id:idEvento,
        micro: microeventArranged, 
        sobre: checked[0].checked || false,
        bankDet: bankDetails.length > 0 ?
           (bankDetails.map(e=>{
            return {clabe: e.clabe ,
            nameBank: e.nameBank ,
            beneficiary: e.nameBeneficiary ,}
          }))[0]
        : null
        ,
        giftRegistry:giftsDetails.length > 0 ? giftsDetails[0].mesa : null,
        giftFrom:giftsDetails.length > 0 ? giftsDetails[0].nameTable : null 
      }})
    }

  }

  return (
    <div>
    <div className='bg-orange-50 h-max pb-20 '>

      <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
         
          <h2 className="mt-6 text-center font-title text-3xl font-bold tracking-tight text-cyan-900">Creando tu <span className='font-spark text-4xl'>
                invitación
                </span> </h2>
          <p className="text-center text-sm text-gray-600">
            <p className="font-body font-light text-stone-600 hover:text-stone-500">
              Comencemos por lo básico
            </p>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full px-6 sm:max-w-lg">
          <div className="bg-stone-50 py-8 px-4 shadow rounded-xl sm:px-10">

        
        <div>

        <h3 className='font-title text-lg font-bold'>Agregar eventos:</h3>
        <p className="font-body font-light text-sm text-stone-600 hover:text-stone-500">
              Añade los detalles 
            </p>

        <MicroEventsComponent photoName={"religiousPhoto"}  typeMicro={"Boda Religiosa"} placePhoto={placePhoto} ></MicroEventsComponent>
        <MicroEventsComponent photoName={"bacheloretesPhoto"}  typeMicro={"Despedida de soltera"} placePhoto={placePhoto} ></MicroEventsComponent>
        <MicroEventsComponent  photoName={"venuePhoto"} typeMicro={"Recepción"} placePhoto={placePhoto} ></MicroEventsComponent>
        </div>

        <form 
        onSubmit={handleStepTwo}
        className='font-body'>
        <GiftTable />

            {/*  
            
            -Microeventos como componente del back
            -checkbox de mesa de regalos ( sobre / link de mesa de regalos ) 
            -Foto portada
            -Foto countdown
            -Foto final
            -Galeria
            -Agredecimientos
            */}

            <button
        type="submit"
        className="text-center mt-4 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
      >
        Continuar
      </button>
        </form>
        </div>
        </div>  
      </div>
    </div>
    </div>
  )
}
