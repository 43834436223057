import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers';

export default function TimePickerC({timeValue , setTimeValue}) {



  return (
    <div className='bg-white'>

    <LocalizationProvider dateAdapter={AdapterDayjs}>
  <TimePicker
  className='w-full'
    value={timeValue}
    onChange={(newValue) => {
      setTimeValue(newValue);
    }}
    renderInput={(params) => <TextField {...params} />}
  />
</LocalizationProvider>
    </div>
  )
}
