import React from 'react'
import CheckBox from './CheckBox'

export default function GiftTable() {

    
  return (
    <div>
    <h3 className='font-title text-lg font-bold'>Agregar mesa de regalos</h3>
    <p className="font-body font-light text-sm text-stone-600 hover:text-stone-500">
              Selecciona cómo deseas recibir los regalos de tus invitados
            </p>
      <CheckBox type="Sobre" extraInfo={false}></CheckBox>
      <CheckBox type="Cuenta bancaria" extraInfo={true} 
    //   handleChange={handleChange}
      infoInput={[
        {placeholder: "Número de cuenta CLABE" , name: "clabe" },
        {placeholder: "Nombre del beneficiario" , name: "nameBeneficiary"},
        {placeholder: "Nombre del banco" , name: "nameBank"}
        ]}  ></CheckBox>
      <CheckBox type="Mesa de regalos" extraInfo={true}  
    //   handleChange={handleChange}
      infoInput={[
        {placeholder: "Link ó Número de mesa de regalos" , name: "mesa"},
        {placeholder: "Donde se tiene la mesa ( liverpool , palacio de hierro etc)" , name: "nameTable"}
        ]} ></CheckBox>
    </div>
  )
}
