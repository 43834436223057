import { ADD_GIFTS, ADD_MICROEVENTS, ADD_PHOTOS, ADD_PHOTOS_MICROEVENTS, LOGOUT, REFETCH, SAVE_PROGRESS, SET_GUEST_LIST, SET_ID, SET_MEMBERSHIP, SET_TOTAL_CONFIRMED, SET_USER } from "./types"

/* eslint-disable import/no-anonymous-default-export */
export default (state, action) => {

    const { payload, type } = action

    switch (type) {
     

        case SAVE_PROGRESS:
            return{
                ...state,
                step : payload.step,
                idEvento : payload.idEvento, 
                progress : payload.progress, 
            }
        case ADD_MICROEVENTS:
            return{
                ...state,
                step : 2,
                microevent : payload, 
            }
        case ADD_GIFTS :
            return{
                ...state,
                gifts:payload
            }
        case ADD_PHOTOS :
            return{
                ...state,
                photos:payload
            }
        case ADD_PHOTOS_MICROEVENTS:
            return{
                ...state,
                placePhotos:payload
            }
        case SET_ID:
            return{
                ...state,
                id:payload
            }
        case SET_MEMBERSHIP:
            return{
                ...state,
                membership:payload
            }
        case SET_GUEST_LIST:
            return{
                ...state,
                guestList:payload
            }
        case SET_TOTAL_CONFIRMED:
            return{
                ...state,
                confirmed: payload.confirmed,
                total:payload.total
            }
        case SET_USER:
            return{
                ...state,
                logged: true,
                idEvento: payload.idEvento,
                membership: payload.membership,
                eventName: payload.eventName,
                user:{
                    id: payload.userId,
                    userName:payload.userName
                }                
            }   
        case LOGOUT:
            return{
                logged : false,
                user: null,
                step: 1,
                total:0,
                confirmed:0,
                refetchLo: false,
                membership:"free",
                eventName:null,
                idEvento: null, 
                progress: null,
                microevent:[],
                guestList:[{
                    id:null , 
                    attributes : {
                    nameInvitation: null,
                    confirmed: null,
                    maxGuests:null,
                    status:null,
                    opened:null
                    }
                }
              ],
                gifts:[
                    {type: 'cuentabancaria', checked: false},
                    {type: 'mesaderegalos', checked: false},
                    {type: 'sobre', checked: false}
                ],
                photos:[
                    {type: 'mainPhoto' , photos: null , blob: [] , max : 3},
                    {type: 'Galery' , photos:null , blob: [] , max : 6},
                    {type: 'Thanks' , photos: null , blob: [], max : 1},
                    {type: 'countdown' , photos: null , blob: [], max : 1}
                ],
                placePhotos:[
                    {type: 'religiousPhoto' , photos: null , blob: [] , max : 1},
                    {type: 'bacheloretesPhoto' , photos:null , blob: [] , max : 1},
                    {type: 'venuePhoto' , photos: null , blob: [], max : 1},
                ]
            }
        case REFETCH:
            return{
                ...state,
                refetchLo: false
            }
        default:
            return state

    }
}
