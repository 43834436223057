import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import State from './Context/State';
import { getToken } from './helpers';

const root = ReactDOM.createRoot(document.getElementById('root'));
const token = getToken()

const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_GRAPH,
  cache: new InMemoryCache(),
  headers: {
    authorization: token ? `Bearer ${token}` : ""
  }
});

root.render(
    <React.StrictMode>
  <BrowserRouter>
  <ApolloProvider client={client}>
  <State>
      <App />
      </State>
      </ApolloProvider>
  </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
