import React, { useContext, useState } from 'react'
import Context from '../../Context/Context'
import InputShort from './InputShort'

export default function CheckBox({ type , extraInfo , infoInput }) {

const[checked, setChecked] =useState(false)

const nameId = type.replace(/\s/g, "").toLowerCase()
const {setGifts , gifts} = useContext(Context)

const [dataForm , setData]= useState()

const handleChange = (ev) =>{
    setData({
    ...dataForm,
    [ev.target.name]:ev.target.value
    })

    const index = gifts.findIndex(((gift)=> gift.type === nameId))

    setGifts({
       ...gifts[index], 
        ...dataForm
    })
    
}

const setCheckedBox = () =>{
    setChecked(!checked)
    setGifts({
        type: nameId,
        checked: !checked})
}

  return (
    <fieldset className="space-y-1">
      <legend className="sr-only">Notifications</legend>
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id={nameId}
            aria-describedby={nameId}
            name={nameId}
            type="checkbox"
            onChange={setCheckedBox}
            className="h-4 w-4 rounded border-gray-300 text-cyan-900 focus:ring-cyan-800"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor={nameId} className="font-medium text-gray-700">
            {type}
          </label>
          <span id={nameId} className="text-gray-500">
            <span className="sr-only">{type}</span>
        </span>
        </div>
      </div>
    
    {
        extraInfo && checked ?

        infoInput.map((input, index)=>{
            return <InputShort 
            key={index}
            title={input.placeholder}  name={input.name} type="text" required={true} handleChange={handleChange}/>
        })
      :
      null
    }

    </fieldset>
  )
}
