import React, { useState } from 'react'
import PhotoPort from '../microComponents/PhotoPort'
import MicroEventsElegance from '../InvitesComponents/ElegantComponents/MicroEventsElegance'
import RegistryElegance from '../InvitesComponents/ElegantComponents/RegistryElegance'
import ElegantHero from '../InvitesComponents/ElegantComponents/ElegantHero'
import CountDownElegance from '../InvitesComponents/ElegantComponents/CountDownElegance'
import Modal from '../Dashboard/Modal'
import ConfirmGuestClient from '../InvitesComponents/ConfirmGuestClient'
import SaveElegant from '../InvitesComponents/ElegantComponents/SaveElegant'
import ThanksElegance from '../InvitesComponents/ElegantComponents/ThanksElegance'
import BannerInviteName from "../InvitesComponents/BannerInviteName"
import Galery from '../InvitesComponents/Galery'
import DressCode from '../InvitesComponents/ElegantComponents/DressCode'
import Parents from '../InvitesComponents/ElegantComponents/Parents'


export default function Elegant({guest , event , preview , style}) {

  const [open, setOpen] = useState(false)

  const guestInfo = {
      id:guest.id,
      attributes:{
          maxGuests : guest.maxGuests,
          nameInvitation : guest.nameInvitation
      }
  }

console.log("EVENTO", event)
  return (
    <div className={` mt-4 bg-[${event.extra.backgroundColor}]`}>
    
        <ElegantHero guest={guest} style={style} preview={preview} photo={event.mainPhoto} extraInf={event.extra} groom={event.nameGroom} bride={event.nameBride} date={event.date}/>
    
        <PhotoPort bgColor={event.extra.backgroundColor ?? null} showRings={event.showRings} image={event.galery[0] } extra={event.extra}></PhotoPort>

        {
          event.extra &&

          event.extra.showParents ? 
          <Parents></Parents>
          : 
          null
        }

        <CountDownElegance  date={event.date} photo={event.countDownPhoto}></CountDownElegance>
        
        <MicroEventsElegance 
        bgColor={event.extra.backgroundColor ?? null}
        extra={event.extra}
        events={event.microEvents} 
        placesPhotos={[
          {name :"Boda Religiosa", image :event.religiousPhoto} , 
          {name :"Recepción", image : event.venuePhoto} ,
          {name :"Despedida de soltera", image : event.bacheloretesPhoto},
          {name :"Boda Civil", image : event.bacheloretesPhoto}
        
        ]
          } />
          <DressCode bgColor={event.extra.backgroundColor ?? null} ></DressCode>
          <RegistryElegance bgColor={event.extra.backgroundColor ?? null} sobre={event.sobre} bank={event.bankAccount} giftRegistry={event.giftRegistry} fromGift={event.fromGift} extra={event.extra}/>
        <SaveElegant image={event.galery[1]} date={event.date} setOpen={setOpen}/>
        

          <Modal open={open} setOpen={setOpen}  componetShow={<ConfirmGuestClient textColor={"text-black"} event={event} guest={guest} data={guestInfo} setOpen={setOpen} color={"bg-[#D3C6BA]"} font={"font-serif"}/>} />

          <Galery bgColor={event.extra.backgroundColor ?? null} gallery={event.galery}></Galery>
          <ThanksElegance photo={event.thanksPhoto} />
    </div>
  )
}
