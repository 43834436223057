import React, { useContext } from 'react'
import Context from '../../Context/Context'

export default function PhotoUpload({addPhotos,handleUploadServices,hiddenFileInput , multiple , type , max , placesOrGalery , placePhoto}) {


    const {photos , placePhotos} = useContext(Context)
    const sectionPhotos = !placesOrGalery ?  photos.filter(photos => photos.type == type) : placePhotos.filter(photos => photos.type == placePhoto)


  return (
    <div>
  
  {
    multiple ? 
    <div>

    <button
        type="button"
        onClick={()=>addPhotos(!placesOrGalery ? type : placePhoto)}
        className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                           
        <span className="mt-2 block text-sm font-medium text-gray-900"> + </span>
        <span className="mb-2 block text-sm font-medium text-gray-900"> {!sectionPhotos[0].blob.length > 0 ? "Subir " : "Reemplazar "}  {multiple ? "fotos" : "foto"} </span>
    </button>

    <input type="file"
        multiple
        onChange={(ev)=>handleUploadServices(ev)}
        accept="image/*"
        ref={hiddenFileInput}
        style={{display:'none'}} 
    /> 
    </div>
    : 
<div>

    <button
        type="button"
        onClick={()=>addPhotos(!placesOrGalery ? type : placePhoto)}
        className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                           
        <span className="mt-2 block text-sm font-medium text-gray-900"> + </span>
        <span className="mb-2 block text-sm font-medium text-gray-900"> {!sectionPhotos[0].blob.length > 0 ? "Subir " : "Reemplazar "}  {multiple ? "fotos" : "foto"} </span>
    </button>

    <input type="file"
        onChange={(ev)=>handleUploadServices(ev)}
        accept="image/*"
        ref={hiddenFileInput}
        style={{display:'none'}} 
    /> 
</div>
  }
  
     
        <div className="flex flex-row space-x-4 mt-3 overflow-scroll mb-6">
    {
        sectionPhotos[0].blob.length > 0 &&

        sectionPhotos[0].blob.slice(0 ,max ).map((image , index)=>{
            return <img className="h-20 w-20 rounded-md" key={index} src={image} alt=" " />
        })

    }
</div>

    </div>
  )
}


