import {  BuildingLibraryIcon, EnvelopeIcon, GiftIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function Registry({sobre,bank,giftRegistry}) {

  return (
    <div className="bg-white font-raleway">
    <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
      <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        <span className="block uppercase">regalos</span>
        
      </h2>
      <p className="mx-auto mt-3 max-w-xl text-lg text-gray-500 sm:mt-4">
            El regalo más importante para nosotros es tu presencia, si quieres regalarnos algo te agradeceriamos que fuera por los siguientes medios
          </p>
      <div className="mt-8 flex justify-center">
        
        <div className='flex flex-col sm:flex-row  justify-evenly gap-8 '>
        {
          sobre &&

          <div className='bg-white shadow-md px-2 py-8 w-64 rounded-xl'>
              <EnvelopeIcon  className='h-20 text-rose-300 mx-auto'></EnvelopeIcon>
              <p className='mt-4'>Sobre </p>
          </div>  
        } 
        
        {
          bank &&
          <div className='bg-white shadow-md px-2 py-8 w-64 rounded-xl'>
              <BuildingLibraryIcon className='h-16 mb-2 mx-auto text-rose-300' />
              <p>CLABE : {bank.clabe}</p>
              <p>Nombre del banco : {bank.nameBank}</p>
              <p>Beneficiario :{bank.beneficiary} </p>
          </div>
        }
        {

          giftRegistry &&

          <div className='bg-white shadow-md px-2 py-8 w-64 rounded-xl'>
          <GiftIcon className='h-16 mb-2 mx-auto text-rose-300'/>
              <p>Mesa de regalos : </p>
              <p>Liverpool
              </p>
          </div>
        }
        </div>
      </div>
    </div>
  </div>
  )
}
