import React, { useEffect, useState } from 'react'
import CountDownElegance from '../InvitesComponents/ElegantComponents/CountDownElegance'
import FullInvite from '../SweetXv/FullInvite'
import Modal from '../Dashboard/Modal'
import ConfirmGuestClient from '../InvitesComponents/ConfirmGuestClient'
import Galery from '../InvitesComponents/Galery'
import XvGallery from '../SweetXv/XvGallery'
import AboutMe from '../SweetXv/AboutMe'
import MicroEventsElegance from '../InvitesComponents/ElegantComponents/MicroEventsElegance'
import RoundThanks from '../SweetXv/RoundThanks'
import MicroEvents from '../InvitesComponents/MicroEvents'
import MicroeventsSweet from '../SweetXv/MicroeventsSweet'
import Header from '../SweetXv/Header'
import RegistrySweet from '../SweetXv/RegistrySweet'
import BannerSweet from '../SweetXv/BannerSweet'
import HeroSlider from '../SweetXv/HeroSlider'
import DressCode from '../SweetXv/DressCode' // import the SpotifyEmbed component
import ReactPlayer from 'react-player'


export default function SweetXv({guest , event}) {

    const [open, setOpen] = useState(false)
    const[play, setPlay]=useState(false)

  const guestInfo = {
      id:guest.id,
      attributes:{
          maxGuests : guest.maxGuests,
          nameInvitation : guest.nameInvitation
      }
  }

  const playyerTrue = () =>{
    setPlay(true)
  }

  const setOpenModal = () =>{
    playyerTrue()
    setOpen(true)
  }

 


  return (
    <div>
      <Header></Header>
          <HeroSlider date={event.date} photo={event.countDownPhoto}></HeroSlider>

            {/* <div onClick={playyerTrue}>PLAY</div> */}
          {
          guest.id &&
          <BannerSweet guest={guest}></BannerSweet>
        }
            <FullInvite playyerTrue={playyerTrue} events={event.microEvents} date={event.date} setOpen={setOpenModal}></FullInvite>

            <AboutMe></AboutMe>

            <Modal open={open} setOpen={setOpen}  componetShow={<ConfirmGuestClient  textColor={"text-black"} event={event} guest={guest} data={guestInfo} setOpen={setOpen} color={"bg-[#fcb8f1]"} font={"font-serif"}/>} />

            
            
            <XvGallery gallery={event.galery}/>

            <MicroeventsSweet events={event.microEvents} 
        placesPhotos={[
          {name :"Misa", image :event.religiousPhoto} , 
          {name :"Recepción", image : event.venuePhoto} ,]
          } />
        <RegistrySweet sobre={event.sobre} bank={event.bankAccount} giftRegistry={event.giftRegistry}/>

          <DressCode></DressCode>


        <RoundThanks image={event.thanksPhoto}></RoundThanks>
        <ReactPlayer volume={1}  playing={play} height={1} url='https://www.youtube.com/watch?v=Oom9_xOxyqU' />

      
    </div>
  )
}
