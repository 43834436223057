 import React from 'react'
 import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
 
 export default function XvGallery({gallery}) {
   return (
        <div className="bg-white py-12">

<div className="flex justify-center">
            <img
              alt=""
              src="https://nicolexv.pilcrow.mx/assets/images/sec-title-flower.png"
              className="w-32"
            />
          </div>
          <div className="mx-auto max-w-2xl px-4  sm:px-6 lg:max-w-7xl lg:px-8">

          <div className="text-center py-4">
          <h2 className="text-2xl uppercase font-semibold raleway tracking-tight text-gray-900 sm:text-4xl">mi sesión de fotos</h2>
          <p className="sm:text-lg text-md text-center  text-gray-500 ">Mis hobbies favoritos además de la lectura son patinar y pintar.</p> 

        </div>
    

        <Masonry columnsCount={3} gutter={"10px"}>
        {gallery.map((product , id) => (
                <div key={id} className="group relative">
                  <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                    <img
                      src={product.url}
                      alt={product.url}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </div>
              ))}
            </Masonry>
           
          </div>
        </div>
  )
 }
 

