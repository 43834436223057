import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { LOGIN_USER } from '../../GraphQL/Mutations'
import Context from '../../Context/Context'
import { getToken, setToken } from '../../helpers'

export default function Login() {

    const [data, setData] = useState({
        email:"email",
        password: "password"
      })
    const [loading, setLoading]= useState(false)
    const navigate = useNavigate()
    const [login]= useMutation(LOGIN_USER)
    const tnk  = getToken();

    const handleChanges = (ev) =>{
        setData({
            ...data,
            [ev.target.name]:ev.target.value
        })
    }

    const {setUser , loadUserData} = useContext(Context)

    const onFinish  = async event => {
        setLoading(true)
        event.preventDefault()
        
        try{
          const response = await login({
            variables:{
                user: data.email,
                password: data.password
            }
          })
          setToken(response.data.login.jwt)

          setUser({
            userId: Number(response.data.login.user.id),
            userName: response.data.login.user.username,
            idEvento: Number(response.data.login.user.events.data[0].id),
            membership: response.data.login.user.events.data[0].attributes.type,
            logged: true,
            eventName:response.data.login.user.events.data[0].attributes.eventName
          })
          

          setTimeout(()=>{
            navigate("/user/dashboard")
            response.data && setLoading(false)
          },1000)
          
          
        }catch(error){
            alert("Contraseña/Usario Incorrecto")
            setLoading(false)
            console.log(error)
        }
      }

      useEffect(()=>{

      
        console.log(tnk)
      },[])

  return (
    <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div className="w-full max-w-sm space-y-10">
      <div>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Ingresa a tu inviter
        </h2>
      </div>
      <form className="space-y-6" onSubmit={onFinish} >
        <div className="relative -space-y-px rounded-md shadow-sm">
          <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
          <div>
            <label htmlFor="email-address" className="sr-only">
              Correo
            </label>
            <input
            onChange={(ev)=>handleChanges(ev)}
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Email address"
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">
              Contraseña
            </label>
            <input
            onChange={(ev)=>handleChanges(ev)}
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Password"
            />
          </div>
        </div>

        

        <div>
        {
            loading ? 

            <button
                className="flex w-full justify-center rounded-md cursor-not-allowed
                bg-cyan-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Cargando
              </button>
            :
            <button
              type="submit"
              className="flex w-full justify-center rounded-md
              bg-cyan-700  px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Ingresar
            </button>
        }
        </div>
      </form>
      


      <p className="text-center text-sm leading-6 text-gray-500">
        Aún no tienes tu inviter?{' '}
        <Link to={"/createInvite/stepOne"}>
        <span  className="font-semibold text-orange-400
         hover:text-orange-500">
        Crea una gratis
        </span>
        </Link>
      </p>
    </div>
  </div>
  )
}



  