import React, { useContext, useEffect } from 'react'
import ConfirmGuest from './ConfirmGuest'
import {
    CheckIcon,
    ShareIcon,
    XMarkIcon,
  } from '@heroicons/react/20/solid'
import CopyLink from './CopyLink'
import { useLazyQuery } from '@apollo/client'
import { GET_GUEST_FROM_EVENT } from '../../GraphQL/Queries'
import Context from '../../Context/Context'
import BuyMembership from './BuyMembership'

export default function GuestList({validate , setComponent , setOpenModal}) {
    
  
  const {idEvento ,  guestList , setGuestsList , refetchLo} = useContext(Context)
  
  const [getGuest,{data ,loading}] = useLazyQuery(GET_GUEST_FROM_EVENT, {variables:{
      id: idEvento
  }})


    useEffect(()=>{
      if(idEvento!== null && data === undefined){
        getGuest()
      }

        if(!loading && data !== undefined){
          setGuestsList(data.guests.data)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data, loading, idEvento , refetchLo])

  
      const statusStyles = {
        pendiente: 'bg-gray-200 text-green-800',
        enviada: 'bg-blue-200 text-green-800',
        vista: 'bg-yellow-100 text-yellow-800',
        confirmadoWeb: 'bg-green-100 text-gray-800',
        declinada: 'bg-red-200 text-gray-800',
        confirmadoManual : 'bg-green-100 text-gray-800'
      }

      const confirmAssit = (id) =>{
        const res = validate()
        if(res){
          setComponent(<ConfirmGuest data={id} setOpenModal={setOpenModal}/>)
        }else{
          setComponent(<BuyMembership/>)
        }
    
        setTimeout(()=>{
          setOpenModal(true)
        },100)
      }

      const shareLink = (id) =>{
        const res = validate()
  
        if(res){
          setComponent(<CopyLink data={id} idEvento={idEvento}/>)
        }else{
          setComponent(<BuyMembership/>)
        }
    
        setTimeout(()=>{
          setOpenModal(true)
        },100)
      }

    
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }



  return (
    <>
             {/* Activity list (smallest breakpoint only) */}
                <div className="shadow sm:hidden">
                  <ul  className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {guestList.map((transaction) => (
                      <li key={transaction.id}>
                        <div  className="block bg-white px-4 py-4 hover:bg-gray-50">
                          <span className="grid grid-cols-2">
                            <span className="flex flex-1 space-x-2 ">
                              <span className="flex flex-col text-sm text-gray-500">
                                <span className="font-medium">{transaction.attributes.nameInvitation}</span>
                                <span>
                                  <span className=" text-gray-900">{transaction.attributes.confirmed}/{transaction.attributes.maxGuests}</span>
                            
                            <td className=" whitespace-nowrap  py-1 text-sm text-gray-500 md:block">
                                  <span
                                    className={classNames(
                                      statusStyles[transaction.attributes.status],
                                      'inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                    )}
                                  >
                                    {transaction.attributes.status}
                                  </span>
                                </td>
                                </span>
                              </span>
                            </span>

                            <span className="isolate inline-flex rounded-md shadow-sm text-center">
                                  <button
                                  onClick={()=>shareLink(transaction)}
                                    type="button"
                                    className="relative text  items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500"
                                  >
                                    <p className='mr-1 text-xs'>Compartir link</p>
                                    <ShareIcon className='w-5 h-5 mx-auto mt-1'></ShareIcon>
                                  </button>
                                  <button
                                  onClick={()=>confirmAssit(transaction)}
                                    type="button"
                                    className="relative -ml-px text   items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500"
                                  >
                                    <p className='mr-1  text-xs '>Confirmar asistencia</p>
                                    <CheckIcon className='w-5 h-5 mx-auto mt-1'></CheckIcon>
                                  </button>
                            </span>

                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
  
                {/* Activity table (small breakpoint and up) */}
                <div className="hidden sm:block">
                  <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 flex flex-col">
                      <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                scope="col"
                              >
                                Nombre de invitación
                              </th>
                              <th
                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                                scope="col"
                              >
                                Confirmados
                              </th>
                              <th
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                scope="col"
                               >
                                Estatus
                              </th>
                              <th
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                                scope="col"
                               >
                                Abierta
                              </th>
                              <th
                                className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                                scope="col"
                              >
                                Acciones
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {guestList.map((transaction) => (
                              <tr key={transaction.id} className="bg-white">
                                <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                  <div className="flex">
                                    <span className="group inline-flex space-x-2 truncate text-sm">
                        
                                      <p className="truncate font-medium text-gray-500 group-hover:text-gray-900">
                                        {transaction.attributes.nameInvitation}
                                      </p>
                                    </span>
                                  </div>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                  <span className="font-medium text-gray-900">{transaction.attributes.confirmed}/{transaction.attributes.maxGuests}</span>
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                  <span
                                    className={classNames(
                                      statusStyles[transaction.attributes.status],
                                      'inline-flex items-center px-1.5 py-0.5 rounded-full text-xs font-medium capitalize'
                                    )}
                                  >
                                    {transaction.attributes.status}
                                  </span>
                                </td>
                                <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                {
                                  transaction.attributes.opened ? 
                                  <CheckIcon className='w-5 h-5 mx-auto mt-1'></CheckIcon>
                                  :
                                  <XMarkIcon className='w-5 h-5 mx-auto mt-1'></XMarkIcon>
                                }
                                </td>
                                <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">

                                <span className="isolate inline-flex rounded-md shadow-sm">
                                  <button
                                   onClick={()=>shareLink(transaction)}
                                    type="button"
                                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500"
                                  >
                                    <span className="sr-only">Previous</span>
                                    <p className='mr-1'>compartir link</p>
                                    <ShareIcon className='w-5 h-5'></ShareIcon>
                                  </button>
                                  <button
                                   onClick={()=>confirmAssit(transaction)}
                                    type="button"
                                    className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500"
                                  >
                                    <span className="sr-only">Next</span>
                                    <p className='mr-1'>confirmar asistencia</p>
                                    <CheckIcon className='w-5 h-5'></CheckIcon>
                                  </button>
                                </span>
                                
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {/* Pagination */}
                      </div>
                    </div>
                  </div>
                </div>
    </>
  )
}
