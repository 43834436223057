import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Context from '../../Context/Context'
import { CREATE_EVENT_BASIC_INFO } from '../../GraphQL/Mutations'
import DatePickerC from '../microComponents/DatePickerC'
import InputShort from '../microComponents/InputShort'
import SecondaryButton from '../microComponents/SecondaryButton'
import axios from 'axios'
import Alert from '../microComponents/Alert'

export default function Form() {

    const [dataForm , setData]= useState({
      url:"ejemplo"
    })
    const [weddingPlanner, setWeddinPlanner] = useState(false)
    const [value, setValue] = useState()
    const [date, setDate] = useState()
    const [activeAlert, setAlert] = useState({active:false , message:"null"})
    const navigate = useNavigate()
    const [loading , setLoading] = useState(false)

    const {saveProgress} = useContext(Context)

    
    const [createEvent ] = useMutation(CREATE_EVENT_BASIC_INFO , {
        onCompleted: (dat)=>successStepOne(dat),
        onError : (e) => handleError(e)
    })

    const handleError =(e) =>{
  
      if(e == "Error: This attribute must be unique"){
        setAlert({active:true , message:"Intenta otro URL de evento"})
      }else{
        setAlert({active:true , message:"algo salio mal intentalo de nuevo"})
      }

      setTimeout(()=>{
        setAlert({active:false , message:"null"})
      },3500)
    }
    const createUsers = async (id) =>{

      setLoading(true)
      // validar si hay o no email del wedding planner 
      const userArray = []

      if(dataForm.emailHalfOne){
        userArray.push({email:dataForm.emailHalfOne , name :dataForm.halfOne })
      }

      if(dataForm.emailhalfTwo){
        userArray.push({email:dataForm.emailhalfTwo , name : dataForm.halfTwo})
      }

      if(dataForm.emailWedding){
        userArray.push({email:dataForm.emailWedding , name : dataForm.emailWedding.substring(0, dataForm.emailWedding.lastIndexOf('@')) })
      }
 

      
      try{



        for(let i = 0 ;  i < userArray.length  ; i++){
          
          let createduser = await axios.post(process.env.REACT_APP_SERVER + `/api/createuser` , {
            data: {
              name:userArray[i].name + Math.floor(Math.random() * 5000),
              email:userArray[i].email,
              password:dataForm.password,
              event: id
            }
          }) 
          console.log(createduser)
          
        }
        
        navigate("/createInvite/stepTwo")
      saveProgress({
        step : 1,
        idEvento : id,
        progress: {
          eventName: dataForm.url.toLowerCase(),
          nameBride: dataForm.halfOne,
          nameGroom: dataForm.halfTwo,
          principal: dataForm.emailHalfOne,
          pareja: dataForm.emailhalfTwo,
          weddingPlanner: dataForm.emailWedding,
          date: date,
          waraburger: dataForm.password, 
          burgerKing: userArray[0].email
      }
      })

      }catch(e){
        console.log(e)
      }
    }

    const successStepOne = async (data) =>{

      setLoading(false)
      createUsers(data.createEvent.data.id)
      
      
    }


    useEffect(()=>{
        if(value !== undefined){
            setDate(value.$d.getFullYear() + "-"  + ('0' + (Number(value.$d.getMonth())+1)).slice(-2)+ "-" + value.$d.getDate() )
        }

        
    },[value])


    const handleChanges = (ev) =>{
        setData({
            ...dataForm,
            [ev.target.name]:ev.target.value
        })
    }
    


    const submitBasicInfo = (event )=>{
        
        event.preventDefault();
        
        
        if(value === undefined){
            alert("Ingresa la fecha de tu evento")
        }
        createEvent({variables:{
            eventName: dataForm.url.toLowerCase(),
            nameBride: dataForm.halfOne,
            nameGroom: dataForm.halfTwo,
            principal: dataForm.emailHalfOne,
            pareja: dataForm.emailhalfTwo,
            weddingPlanner: dataForm.emailWedding,
            date: date
        }})
    }


  return (
    <div>
    <div className='bg-orange-50 h-max pb-20 '>
    

      <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
         
          <h2 className="mt-6 text-center font-title text-3xl font-bold tracking-tight text-cyan-900">Creando tu <span className='font-spark text-4xl'>
                invitación
                </span> </h2>
          <p className="text-center text-sm text-gray-600">
            <p className="font-body font-light text-stone-600 hover:text-stone-500">
              Comencemos por lo básico
            </p>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full px-6 sm:max-w-lg">
          <div className="bg-stone-50 py-8 px-4 shadow rounded-xl sm:px-10">

          {
            !loading ? 
            <form action='POST' onSubmit={submitBasicInfo}>
            <InputShort title="URL del evento" handleChange={handleChanges} name="url" type="text" required={true}></InputShort>
            <p className='font-body font-light text-sm ml-1 pb-2 mt-1 text-stone-500'>invitar.me/{dataForm.url.toLowerCase()} </p>

            <InputShort title="Nombre  persona 1" handleChange={handleChanges} name="halfOne" type="text" required={true}></InputShort>
            <InputShort title="Email 1" handleChange={handleChanges} name="emailHalfOne" type="email" required={true}></InputShort>
            <div className="py-4 inset-0 flex items-center">
                  <div className="w-full border-t border-stone-600" />
                </div>
            <InputShort title="Nombre persona 2" handleChange={handleChanges} name="halfTwo" type="text" required={true}></InputShort>
            <InputShort title="Email 2" handleChange={handleChanges} name="emailhalfTwo" type="email" required={true}></InputShort>
            
            {
            !weddingPlanner &&
            <div className='my-4'>
            <SecondaryButton text={"Agregar wedding planner"} type={"button"} action={setWeddinPlanner} ></SecondaryButton>
            </div>
            }

            {
                weddingPlanner && 
                <div>
                    
                <div className="py-4 inset-0 flex items-center">
                  <div className="w-full border-t border-stone-600" />
                </div>
                <InputShort title="Email wedding planner" handleChange={handleChanges} name="emailWedding" type="text" required={false}></InputShort>
                </div>
            }
            <p className='font-body text-md ml-1 mt-1 text-stone-500'>Día de la boda </p>

            <DatePickerC value={value} setValue={setValue}></DatePickerC>

            <InputShort title="Contraseña" handleChange={handleChanges} name="password" type="password" required={true}></InputShort>
            <p className='font-body text-md ml-1 mt-1 text-stone-500'>Será la misma contraseña para todos </p>

            <button
        type="submit"
        className="text-center w-full mt-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
      >
        Continuar
      </button>

      {
        activeAlert.active &&
        <Alert message={activeAlert.message}></Alert>
      }
        </form>
            :

            <div className='text-center'>
              <div className='flex justify-center'>

      <svg className="animate-spin my-6 -ml-1 mr-3 h-20 w-20 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
              </div>
              <span className='mt-8 font-title text-md font-bold'>

      Cargando...
              </span>
    </div>
          }
        


        </div>
        </div>  
      </div>
    </div>
    </div>
  )
}
