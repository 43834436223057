import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
var LocalizedFormat = require('dayjs/plugin/localizedFormat')

dayjs.extend(LocalizedFormat)


export default function SaveElegant({setOpen , date, image}) {

  const [time, setTime]  = useState(false)
  

  useEffect(()=>{
    setTimeout(()=>{
      setTime(true)
    },500)
  },[])
    return (
      <section>
            <div className="relative overflow-hidden mt-12">
               <div className="absolute inset-0">
                <img
                  src={image.url}
                  alt=""
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="relative bg-black bg-opacity-55 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
                <div className="relative mx-auto mt-12 flex  flex-col items-center text-center">
                  <h2 id="social-impact-heading" className="text-5xl text-white sm:text-6xl">
                    <span className="block sm:inline font-cursiveEle ">Save the Date</span>
                  </h2>
                  {
                    time &&
                    <p className=" uppercase font-serif text-2xl text-white">
                    {dayjs(date).format('LL')}
                    </p>
                  }
                      <button
                      onClick={()=>setOpen(true)}
                  type="button"
                  className="uppercase font-serif inline-flex items-center rounded-md border border-transparent bg-[#D3C6BA] px-4 relative top-6 py-2 text-md  text-black shadow-sm"
                >
                  Confirmar asistencia
                </button>
                  {/* <p className="mt-12 font-serif uppercase tracking-wide text-lg text-white">
                    Estas invitado
                  </p> */}
                  
                </div>
              </div>
            </div>
          </section>
    )
  }
  