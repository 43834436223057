import { FolderPlusIcon } from '@heroicons/react/20/solid'
import React from 'react'

export default function FileUpload({addPhotos,handleUploadServices,hiddenFileInput ,  type , fileName}) {

   

  return (
    <div>
    
  
  
    <button
    type="button"
    onClick={()=>addPhotos(type)}
    className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-electric-400">
                       
    <span className="mt-2 block text-sm font-medium text-gray-900"> 
    <FolderPlusIcon
    className='mx-auto h-12 w-12 text-gray-400'
    ></FolderPlusIcon>

                    </span>
    <span className="mb-2 block text-sm font-medium text-gray-900">{fileName === "" ? "Subir archivo de excel" :"Remplazar archivo" }  </span>
</button>
  
      <input type="file"
          onChange={(ev)=>handleUploadServices(ev)}
          ref={hiddenFileInput}
          style={{display:'none'}} 
      /> 

  
      
    </div>
  )
}
