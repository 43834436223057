import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'

export default function CopyGeneralLink({eventName}) {

    const [alert , setAlert] = useState(false)
    
// console.log(eventName)
    const copyLinkButton = () =>{
        
        navigator.clipboard.writeText(process.env.REACT_APP_CLIENT + "/" + eventName)
        setAlert(true)
    }
  return (
    <div className="pb-12">
    <div>
    <label htmlFor="search" className="block text-md font-bold text-gray-700">
      Compartir enlace
    </label>
    <p className='text-sm'>Comparte este enlace a tus invitados para que puedan confirmar su asistencia</p>
    

    {
      alert &&
    <div className='my-3'> 
    <p className='text-sm bg-orange-300 text-center text-black rounded-md py-2'> Link Copiado </p>
    </div>
    }
    
    <div className="relative mt-1 flex items-center">
      <input
        type="text"
        name="search"
        id="search"
        readOnly
        value={process.env.REACT_APP_CLIENT + "/" + eventName }
        
        className="block w-full rounded-md border-gray-300 pr-12 shadow-sm focus:border-orange-300 focus:ring-orange-300 sm:text-sm"
      />
      <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
        <span 
        onClick={copyLinkButton}
        className="inline-flex items-center rounded border border-gray-200 px-2 font-sans text-sm font-medium text-gray-400">
        
          <ClipboardDocumentIcon className="h-5 w-5" /> 
        </span>
      </div>
    </div>
  </div>
  </div>
  )
}
