import { useMutation } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Context from '../../Context/Context.jsx'
import { POST_GUEST } from '../../GraphQL/Mutations.js'
import InputShort from "../microComponents/InputShort.jsx"
export default function AddGuest({setOpenModal }) {
  
const{idEvento, guestList , setGuestsList} = useContext(Context)

const [dataForm , setData]= useState({
  nameInvitation : null,
  maxNumber : null
})


const success = (data) =>{  
  setGuestsList([{
    id:data.createGuest.data.id , 
    attributes : {
    nameInvitation: data.createGuest.data.attributes.nameInvitation,
    confirmed: 0,
    url:data.createGuest.data.attributes.url,
    maxGuests:data.createGuest.data.attributes.maxGuests,
    status:"pendiente",
    opened:false
    }
}, ...guestList])


  setTimeout(()=>{
    setOpenModal(false)
  },200)
}

const [postGuest ] = useMutation(POST_GUEST , {
  onCompleted: (data)=>success(data)
})

const {id} = useParams()
// console.log(id)

const navigate = useNavigate()

const handleChanges = (ev) =>{
        setData({
            ...dataForm,
            [ev.target.name]:ev.target.value
        })
    }

const uploadList = ( )=>{
  alert("hi")
  // navigate("/addGuestList") 
  // agregar el id a la URL
}



const addGuest = ( )=>{

  if(dataForm.nameInvitation !== null && dataForm.maxNumber !== null){

    postGuest({variables:{
      guest: dataForm.nameInvitation,
      maxGuest: Number(dataForm.maxNumber),
      id: idEvento ? idEvento : id
    }})
  }else{
    alert("Completa todos los campos")
  }

}


  return (
    <div>
    <p className="block text-md font-bold text-gray-700">
        Agregar invitado
      </p>
      <p className='text-sm mb-3'>Agrega tus invitados de forma manual o descargando el formato</p>

    <InputShort 
    title="Nombre de la invitación"
    handleChange={handleChanges}
    name="nameInvitation"
    type="text" 
    required={true}
    ></InputShort>

    <InputShort 
    className="my-3"
    title="Cantidad de invitados"
    handleChange={handleChanges}
    name="maxNumber"
    type="number" 
    required={true}
    ></InputShort>

    <button
    type="button"
    onClick={addGuest}
    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm  mt-2"
                        
                      >
                        Agregar invitado
                      </button>

                      <button
    type="button"
    onClick={uploadList}
    className="inline-flex w-full justify-center rounded-md border border-cyan-800 px-4 py-2 text-base font-medium text-cyan-800 shadow-sm  focus:ring-offset-2 sm:text-sm mb-12 mt-2"
                        
                      >
                        Subir lista de invitados
                      </button>
    
    </div>

    
  )
}
