import dayjs from 'dayjs'
import React from 'react'

export default function SaveTheDate({setOpen , date}) {

  
  return (
    <section>
          <div className="relative overflow-hidden ">
             {/*<div className="absolute inset-0">
              <img
                src="https://tailwindui.com/img/ecommerce-images/home-page-01-feature-section-01.jpg"
                alt=""
                className="h-full w-full object-cover object-center"
              />
            </div>*/}
            <div className="relative bg-gray-900 bg-opacity-75 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
              <div className="relative mx-auto flex  flex-col items-center text-center">
                <h2 id="social-impact-heading" className="text-5xl text-white sm:text-6xl">
                  <span className="block sm:inline font-cursivemodern ">Save the Date</span>
                </h2>
                <p className="mt-6 font-bold text-3xl text-white">
                  {dayjs(date).format('LL')}
                </p>
                    <button
                    onClick={()=>setOpen(true)}
                type="button"
                className="uppercase  inline-flex items-center rounded-md border border-transparent bg-rose-300 px-4 relative top-6 py-2 text-md  text-white shadow-sm"
              >
                Confirmar asistencia
              </button>
                <p className="mt-12 uppercase tracking-wide text-lg text-white">
                  you are invited
                </p>
                
              </div>
            </div>
          </div>
        </section>
  )
}
