import React from 'react'

export default function DropDown({id,name,defaultValue,options,handleChange}) {




  return (
    <div className=' mb-10'>
   
    <select
      id={id}
      name={name}
      className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base sm:text-sm"
      defaultValue={defaultValue}
      onChange={handleChange}
    >
    {
        options.map(option =>{
            return <option>{option}</option>
        })
    }

    </select>
  </div>
  )
}
