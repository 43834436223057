import { PencilSquareIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Context from '../../Context/Context'
import DatePickerC from './DatePickerC'
import InputShort from './InputShort'
import TimePickerC from './TimePickerC'
import PhotoUpload from './PhotoUpload'

export default function MicroEventsComponent({typeMicro , photoName}) {

const {addMicroEvent, microevent} = useContext(Context)

let namePlace = "place" + typeMicro.split(/\s/).join('').toLowerCase().replace(new RegExp(/[òóôõö]/g),"o")
let adressPlace = "address" + typeMicro.split(/\s/).join('').toLowerCase().replace(new RegExp(/[òóôõö]/g),"o")
let googleLink = "link" + typeMicro.split(/\s/).join('').toLowerCase().replace(new RegExp(/[òóôõö]/g),"o")

const [dataMicroEvents , setDataMicroEvents] = useState({
  [namePlace]:'',
  [adressPlace] :'',
  [googleLink]: ""
})
const [dateTime, setDateTime] = useState()
const [value, setValue] = useState()
const [timeValue, setTimeValue] = useState()
const [openForm, setOpenForm] = useState(false)
const [microEventOn , setMicroEventOn] = useState(false)
const[microEventData, setMicroEventData] = useState()
const hiddenFileInput = useRef();
const [currentPhotoName , setCurrentPhotoName] = useState()
const [loading , setLoading] = useState(false)
const {addPhotosContextPlaces , placePhotos , idEvento} = useContext(Context)


useEffect(()=>{
  if(microevent.length > 0){
    
    setMicroEventData(microevent.filter(event => event.type === typeMicro))
    microevent.forEach(event => {
      if(event.type === typeMicro){
        setMicroEventOn(true)
      }
    });
  }
},[microevent , typeMicro])

const handleMicroEvents = (ev) =>{
    setDataMicroEvents({
        ...dataMicroEvents,
        [ev.target.name]:ev.target.value
    })
}


const createMicroEvent = (ev)=>{
  ev.preventDefault()

  if(timeValue === undefined || value === undefined){
    alert("Ingresa una fecha y hora")
  }else{
    addMicroEvent({
      type: typeMicro,
      placeName : dataMicroEvents[namePlace],
      googleLink : dataMicroEvents[googleLink],
      placeAddress: dataMicroEvents[adressPlace],
      dateTime : dateTime.toISOString()
    })
    setOpenForm(false)
    setTimeout(()=>{
      setMicroEventOn(true)
    }, 1500)
  }

  uploadPhotos()

}


useEffect(()=>{
    if(value !== undefined && timeValue!== undefined){
        const date = new Date(value.$d.getFullYear() , ('0' + (Number(value.$d.getMonth()))).slice(-2), ('0' + (Number(value.$d.getDate()))).slice(-2) , timeValue.$d.getHours(), timeValue.$d.getMinutes())
        setDateTime(date)
    }
    

},[value, timeValue])

const addPhotos = (id) =>{
  // console.log("ADDPHOTOO", id)
  hiddenFileInput.current.click();
  setCurrentPhotoName(id)
}


const handleUploadServices = (ev) =>{
  addPhotosContextPlaces({
    max: placePhotos.filter(e=>e.type === currentPhotoName)[0].max , 
    type: currentPhotoName,
    photos : ev.target.files,
    blob: Object.values(ev.target.files).map(e=> URL.createObjectURL(e))
  })
}


const uploadPhotos = async( )=>{
const validationPhotos = placePhotos.filter(e=> (e.blob.length === 0 && e.type == photoName))

let photoTuo = placePhotos.filter(e=> e.type == photoName)
console.log("FILTER TO ADD FORM DATA" , photoTuo[0].type)


if(validationPhotos.length > 0 ){
  alert("Sube almenos una foto")
  
}else{
  
  try{
    
  
      
      setLoading(true)
      
        const dataF = new FormData();
        dataF.append("files" , photoTuo[0].photos[0])
        dataF.append('ref', 'api::event.event')    // collection name
        dataF.append('refId', `${idEvento}`)   // post ID
        dataF.append('field', `${photoTuo[0].type}`)
        
        const res = await fetch(process.env.REACT_APP_SERVER +'/api/upload', {
          method: 'post',
          body: dataF
        });


       if(res.status == 200){
        setLoading(false)
       }
    
    
    }catch(e){
      console.log(e)
  }
}




}




  return (
    <div className='my-3'>

    {
    
    openForm ? 

    <div className='relative block w-full rounded-lg border-2 px-4 border-dashed border-gray-300 p-2 text-left focus:outline-none focus:ring-2'>
    
    <XMarkIcon 
    onClick={()=>setOpenForm(false)} 
    className='h-5 w-5 cursor-pointer'
    />

    <h3 className='my-2 font-title text-lg ml-2 font-bold'>{typeMicro}</h3>
    

    <form
    onSubmit={createMicroEvent}
    >
    
    <InputShort 
    value={dataMicroEvents[namePlace]} 
    title="Nombre del lugar" 
    handleChange={handleMicroEvents} 
    name={namePlace} 
    type="text" 
    required={true}
    />
    

    <InputShort 
    value={dataMicroEvents[adressPlace]} 
    title="Dirección del lugar" 
    handleChange={handleMicroEvents} 
    name={adressPlace} 
    type="text" 
    required={true}/>

    <InputShort 
    value={dataMicroEvents[googleLink]} 
    title="Link de google (dirección)" 
    handleChange={handleMicroEvents} 
    name={googleLink} 
    type="text" 
    required={true}
    />
    
    <DatePickerC 
    value={value} 
    setValue={setValue}
    />
    
    <TimePickerC 
    timeValue={timeValue} 
    setTimeValue={setTimeValue} 
    />

    <p className="font-body  text-md text-stone-600 hover:text-stone-500">
              Foto del lugar (1 fotos)
            </p>
          <PhotoUpload addPhotos={addPhotos} handleUploadServices={handleUploadServices} type={typeMicro}  hiddenFileInput={hiddenFileInput} multiple={false}  max={1} placesOrGalery={true} placePhoto={photoName}/>


    <button
        type="submit"
        className="my-4 text-center w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-900"
      >
        Agregar {typeMicro}
      </button>
    </form>
    
    </div>
        : 

        !microEventOn?

        <button
    onClick={()=>setOpenForm(true)}
    type="button"
    className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-2 text-left hover:border-cyan-900 focus:outline-none focus:ring-2"
  >
   
    <span className="mt-2 ml-4 block text-sm text-gray-900 text-center">Agregar {typeMicro}</span>
  </button>
        : 
        
        !loading ? 

        
        <div
    className="font-body relative block w-full rounded-lg border-2 border-solid border-orange-300 p-2 text-left font-light"
  >
  
  <PencilSquareIcon 
  onClick={()=>setOpenForm(true)}
  className='h-9 w-9 absolute right-0 top-0 px-2 cursor-pointer text-cyan-900'/>
  <div >

   <p className='mt-1'>Lugar :{microEventData[0].placeName !== undefined ? microEventData[0].placeName : null}</p>
   <p>Dirección :{microEventData[0].placeAddress !== undefined ?microEventData[0].placeAddress: null}</p>
   <p>Fecha: {microEventData[0].placeAddress !== undefined ?  new Date(microEventData[0].dateTime).toLocaleDateString('es-MX', {dateStyle: "full"}) : null}</p>
   <p>Hora: {microEventData[0].placeAddress !== undefined ? new Date(microEventData[0].dateTime).toLocaleTimeString('es-MX' , { hour: 'numeric', minute: '2-digit' , hour12: true }) : null}</p>
   
  </div>

  </div>

  : 

  <div
    className="font-body relative block w-full rounded-lg border-2 border-solid border-orange-300 p-2 text-left font-light"
  >
  
  <p>Cargando..</p>

  </div>

    }
    
    </div>
  )
}
