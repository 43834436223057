import React, { useContext, useState } from 'react'
import Context from '../../Context/Context'
import { setToken } from '../../helpers'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { LOGIN_USER } from '../../GraphQL/Mutations'

export default function LoaderWhileLoggin() {


    const navigate = useNavigate()
  const {progress , setUser} = useContext(Context)
  const [loading , setLoading] = useState(false)
  const [login]= useMutation(LOGIN_USER)


    const loginFunction  = async () => {
        setLoading(true)
        console.log("entre al login")
        try{
          const response = await login({
            variables:{
                user: progress.burgerKing,
                password: progress.waraburger
            }
          })
  
          console.log(response)
  
          setUser({
            userId: Number(response.data.login.user.id),
            userName: response.data.login.user.username,
            idEvento: Number(response.data.login.user.events.data[0].id),
            membership: response.data.login.user.events.data[0].attributes.type,
            logged: true
        })
        setToken(response.data.login.jwt)

        setTimeout(()=>{
            navigate("/user/dashboard")
            setLoading(false)
        },2000)
        
        }catch(error){
        navigate("/user/login")
            console.log(error)
        }
      }

      



  return (
    <div className='bg-orange-50 h-max pb-20 '>
    <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
    <div className='text-center'>
    <div className='flex justify-center'>
    
    <svg className="animate-spin my-6 -ml-1 mr-3 h-20 w-20 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    </div>
    <span className='mt-8 font-title text-md font-bold'>
    
    Cargando...
    </span>
    </div>

    {
        !loading ?
        <button
        type="button"
        onClick={()=>loginFunction()}
        className="text-center mt-4 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
      >
        Ver resultado
      </button>
        :

        <button
        type="button"

        className="text-center mt-4 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300 cursor-not-allowed"
      >
        Cargando ...
      </button>
      }
    </div>
    </div>
  )
}
