import React from 'react'

export default function RoundThanks({image}) {
    
    // console.log(image)
    return (
        <div className='sm:mt-12 mt-4'>

<div className="flex justify-center py-12">
            <img
              alt=""
              src="https://res.cloudinary.com/dxweypnlj/image/upload/v1720671533/danna_f123de9ab4.jpg?updated_at=2024-07-11T04:18:53.644Z"
              className="w-64"
            />
          </div>
          
            <div className="flex justify-center">
            
            <img
              className="rounded-full sm:w-96 w-64"
              src={image}
              alt="wedding"
              />
              
              </div>

              
        
              <div className='flex justify-center mx-4'>
              <p className="font-cursivemodern mx-auto  my-2 text-center  sm:text-7xl text-6xl text-black ">
              Nos vemos pronto
              </p>
              </div>
              </div>
        
          )
}


