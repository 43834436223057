 import React from 'react'
 
 export default function FullInvite({events , setOpen}) {

   return (
     <div>
       <div className="flex min-h-full flex-1 flex-col justify-center py-12 ">
    

    <div className="sm:mt-10 sm:mx-auto sm:w-3/4 sm:max-w-[480px] sm:px-2 px-10 relative ">

          <img className='w-36 absolute sm:-left-10 sm:-top-20 left-0 -top-10 ' src='https://nicolexv.pilcrow.mx/assets/images/invitation-left-img.png'/>

          <div className="bg-white px-6 py-12 shadow-lg sm:px-12 z-50">


            <div className='border-solid border-4 border-rose-300 p-12'>


            <p className="sm:text-5xl text-4xl text-center font-cursivemodern text-gray-900 pt-6">Save the Date</p> 
            <p className="sm:text-lg text-md text-center  text-gray-500 ">For</p> 
            <p className="sm:text-xl text-lg text-center font-bold text-gray-900 ">Danna XV</p> 
            <p className="sm:text-lg text-lg text-center  text-gray-900 ">Domingo 11 de Agosto </p> 
            <p className="sm:text-lg text-lg text-center  text-gray-900 ">6:00 pm</p> 

            <p className="mt-3 sm:text-xl text-sm mx-auto w-3/4 text-center text-gray-500">{events.filter(e=>e.title = "Recepción").length ? events.filter(e=>e.title = "Recepción")[0].address : null}</p>

            <div className='flex flex-row justify-center pb-10'>

            <button
                      onClick={()=>setOpen()}
                      type="button"
                      className="uppercase font-bold inline-flex items-center rounded-md border border-transparent bg-[#E7427E] px-4 relative top-6 py-2 sm:text-md text-xs  text-white shadow-sm"
                      >
                  Confirmar asistencia
                </button>
                    </div>


            </div>
          </div>

          <img className='w-36 absolute sm:-bottom-10 sm:-right-10 -bottom-16 right-0' src='https://nicolexv.pilcrow.mx/assets/images/invitation-right-image.png'/>

        </div>
      </div>
     </div>
   )
 }
 