
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function Help() {
  return (

    <div className="pb-12 text-center">
    <div>
    <label htmlFor="search" className="block text-md font-bold text-gray-700">
      Solicita Ayuda
    </label>
    <p className='text-xl mb-10'>Si algo falla no te preocupes contactanos por whatsapp </p>
    <a rel='noreferrer' target="_blank"  href='https://api.whatsapp.com/send?phone=5218126376743&text=Ayuda, con mi invitación' ><p className='text-lg'>Da clic </p></a>
 
    <span className='flex flex-row justify-center text-blue-500'>
    <InformationCircleIcon className='w-8'></InformationCircleIcon>
    <a rel='noreferrer' target="_blank"  href='https://api.whatsapp.com/send?phone=5218126376743&text=Ayuda, con mi invitación' ><p className='text-lg'>8126376743 </p></a>
    </span>
  
    

   
    
    
  </div>
  </div>
  )
}
