import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { GENERAL_GUEST } from '../../GraphQL/Mutations'
import DropDown from '../microComponents/DropDown'
import InputShort from '../microComponents/InputShort'


export default function ConfirmGeneral({setOpenModal , color , event}) {

    console.log(event.id)

    const [number , setNumber]= useState(1)
    const [alertLo , setAlert] = useState(false)
    const [guest , setGuest] = useState("")
    
    const handleChanges = (ev) =>{
        setGuest(ev.target.value)
    }

    const maxNumber = 5
    const arrNumbers = []
    
    for(let i = 1; i<= maxNumber ; i++){
      arrNumbers.push(i)
    }

    const succes = () =>{
      setAlert(true)
      setTimeout(()=>{
          setOpenModal(false)
      },500)
    }

   

    const confirmButton = (ev) =>{
      
      ev.preventDefault()
      if(guest !== ""){
        
        updateGuest({
          variables:{
            confirmed:Number (number),
            id:event.id,
            guest:guest
          }
        })
      }else{
        alert("Hello! I am an alert box!!");
      }
    }

    const handleChange = (number) =>{
      setNumber(number.target.value)
    }

    const [updateGuest] = useMutation(GENERAL_GUEST , {
      onCompleted : () => succes()
    })

  return (
    <div>
    <div className="pb-12">
    <form >
     <div>

     <label htmlFor="search" className="block text-md font-bold text-gray-700">
       Confirmar asistencia de :
     </label>
     <div className=''>


     <InputShort 
    title="Nombre"
    handleChange={handleChanges}
    name="nameInvitation"
    type="text" 
    required={true}
    ></InputShort>
    <label htmlFor="search" className="block text-md font-bold text-gray-700">
       Cantidad de asistentes :
     </label>

    <DropDown id={"1"} name={"confirmed"} defaultValue={1} options={arrNumbers} handleChange={handleChange} ></DropDown>
     
     </div>

     {
       alertLo &&
     <div className='my-3'> 
     <p className={`text-sm ${color ? color : "bg-orange-300"} text-center text-black rounded-md py-2`}> Invitado confirmado </p>
     </div>
     }
     
     <button
                       type="submit"
                       className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-smsm:text-sm ${color ? color : "bg-orange-300"} `}
                       onClick={(ev) => confirmButton(ev)}
                     >
                       Confirmar invitado
                     </button>
   </div>
</form>
   </div>
   </div>
  )
}
