import React, { useContext, useEffect, useState } from 'react'
import {
    DocumentCheckIcon,
    DocumentIcon,
  } from '@heroicons/react/20/solid'
import Context from '../../Context/Context'
import axios from 'axios'

export default function Cards() {

    const {idEvento} = useContext(Context)

    // console.log(idEvento)

    const [cards , setCards] =useState ([
        { id:"total" , name: 'Invitados total', icon:  DocumentIcon, amount: null ,color :"gray" },
        { id:"confirmados" , name: 'Confirmados', icon: DocumentCheckIcon, amount: null , color:"green"}
      ])

      
    const getTotals = async () =>{
      try{
        const res = await axios.get(process.env.REACT_APP_SERVER + `/api/countguest/${idEvento}`) 
        setCards([
            { id:"total" , name: 'Invitados total', icon:  DocumentIcon, amount: res.data.total ,color :"gray" },
            { id:"confirmados" , name: 'Confirmados', icon: DocumentCheckIcon, amount: res.data.confirmed , color:"green"}
          ])

      }catch(e){
        console.log(e)
      }
    }

    useEffect(()=>{
      if(idEvento !== null){
        getTotals()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[idEvento])


      
  return (
    <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <div className="mt-2 grid  gap-5 grid-cols-2 lg:grid-cols-3">
                    {/* Card */}
                    {cards.map((card) => (
                      <div key={card.name} className="overflow-hidden rounded-lg bg-white shadow">
                        <div className="p-2 sm:p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <card.icon className={`h-6 w-6 text-${card.color}-400`}  aria-hidden="true" />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="truncate text-sm font-medium text-gray-500">{card.name}</dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">{card.amount}</div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
  )
}
