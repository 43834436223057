import { gql } from "@apollo/client"

export const GET_GUEST_FROM_EVENT = gql`
query getGuests($id:ID!){
    guests(
    sort: "id:desc" ,
    pagination:{
      start:0
      limit:-1
    },
    filters:{event:{id: {eq:$id}}}){
      data{
        id
        attributes{
          confirmed
          url
          maxGuests
          status
          opened
          nameInvitation
          event{
            data{
              attributes{
                eventName
              }
            }
          }
        }
      }
    }
  }
`

export const GET_ME = gql`
query getMe{
  me{
    id
    username
    events{
      data{
        id
        attributes{
          type
          eventName
        }
      }
    }
  }
}
`



export const TOTAL_GUESTS_BY_EVENT = gql`
query getTotalGuest($id:ID!) {
    guests(filters:{event:{id:{eq:$id}}}){
     meta{
       pagination{
         total
       }
     }
   }
   }
`

export const GUESTS_CONFIRMED = gql`
query getTotalGuest($id:ID!) {
    guests(
    filters:{
     event:{
       id:{
         eq:
         $id
       }
     },
     status:{
       in:["confirmadoWeb" , "confirmadoManual"]
     } 
   }){
     meta{
       pagination{
         total
       }
     }
   }
   }
`

export const EVENT_INFO = gql`
query getEvent($nameEvent: String){
  events(filters:{eventName:{eq:$nameEvent}}){
    data{
      id
      attributes{
        eventName
        date
        nameGroom
        nameBride
        sobre
        style
        fromGift
        extraData
        giftsRegistry
        titleNames
        showRings
        Thanks{
          data{
            attributes{
              url
            }
          }
        }
        countdown{
          data{
            attributes{
              url
            }
          }
        }
        mainPhoto{
          data{
            attributes{
              url
            }
          }
        }
        Galery{
          data{
            attributes{
              url
            }
          }
        }
        bankAccount{
          clabe
          nameBank
          beneficiary
        }
      	microEvents{
          title
          address
          place
          dateTime
          googleLink
        }
        Thanks{
          data{
            attributes{
              url
            }
          }
        }
        religiousPhoto{
          data{
            attributes{
              url
            }
          }
        }
        bacheloretesPhoto{
          data{
            attributes{
              url
            }
          }
        }
        venuePhoto{
          data{
            attributes{
              url
            }
          }
        }
      }
    }
  }
}
`


export const GUEST_INFO = gql`
query getGuest($id: ID!){
  guest(id:$id){
    data{
      id
      attributes{
        maxGuests
        nameInvitation
        event{
          data{
            id
          }
        }
      }
    }
  }
}
`


