import React from 'react'

export default function ThanksElegance({photo}) {
    return (
      <section>
      <div className="relative overflow-hidden mt-12">
        <div className="absolute inset-0">
          <img
            src={photo}
            alt=""
            className="h-full w-full object-cover object-center"
          />
        </div>
        <div className="relative bg-black bg-opacity-75 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">
  
          <div className="relative mx-auto flex  flex-col items-center text-center">
            <h2 id="social-impact-heading" className="text-5xl text-white sm:text-6xl">
              <span className="block sm:inline  font-cursiveEle ">¡Te esperamos!</span>
            </h2>
           
          </div>
        </div>
      </div>
    </section>
    )
  }
