import React from 'react'

export default function SecondaryButton({text , type , action}) {
    

  
    return (
    <button
    type={type}
    onClick={action}
    className="flex font-body w-full justify-center rounded-md border border-transparent py-2 px-4 text-md font-medium text-cyan-800 shadow-sm hover:bg-cyan-900 focus:outline-none focus:ring-2 border-cyan-900 hover:text-white focus:ring-cyan-900 focus:ring-offset-2"
  >
    {text}
  </button>
  )
}
