import { Fragment, useContext, useState } from 'react'
import { Dialog,  Transition } from '@headlessui/react'
import {
  Bars3CenterLeftIcon,
  CalendarIcon,
  EyeIcon,
  FireIcon,
  InformationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import {
  LinkIcon,
  PhotoIcon,
  UserIcon,
} from '@heroicons/react/20/solid'
import Context from '../../Context/Context'
import Modal from './Modal'
import BuyMembership from './BuyMembership'
import AddGuest from './AddGuest'
import {  Link, useNavigate } from 'react-router-dom'
import Cards from './Cards'
import GuestList from './GuestList'
import CopyGeneralLink from './CopyGeneralLink'
import Router from '../Templates/Router'
import Help from './Help'

const navigation = [
  { name: 'Invitados', href: '#', icon: InformationCircleIcon, current: true },
  // { name: 'Eventos', href: '#', icon: CalendarIcon, current: false },
  // { name: 'Fotos', href: '#', icon: PhotoIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {

  const {membership , eventName , idEvento} = useContext(Context)
  
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [componentDynamic , setComponent] = useState (<BuyMembership/>)
  const [prev , setPrev] = useState(false)
  
  const navigate = useNavigate()


  const validate = () =>{
    switch (membership) {
      case "free":
        return false
      
      case "premium":
        return true
      
      case "complete":
          return true
      
          default:
        break;
    }
  }
  const addList = () =>{
    navigate(`/addGuestList/${idEvento}`)
    // agregar el id a la URL
  }
  const addGuest = () =>{
    
    setComponent(<AddGuest setOpenModal={setOpenModal}/>)
    setTimeout(()=>{
      setOpenModal(true)
    },100)

  }

  function generarCadenaConNumeroAntesDeJ(numero) {
    if (isNaN(numero) || numero < 0) {
      throw new Error("El número debe ser un valor numérico mayor o igual a 0.");
    }
  
    const numeroStr = numero.toString();
  
    if (numeroStr.length > 9) {
      throw new Error("El número antes de 'J' debe tener un máximo de 9 dígitos.");
    }
  
    const jPosition = 5;
  
    let cadena = '';
  
    for (let i = 0; i < 30; i++) {
      if (i === jPosition) {
        cadena += 'J';
      } else if (i === jPosition + 1) {
        cadena += numeroStr;
      } else {
        // Generar una letra aleatoria en mayúscula o minúscula
        const letraAleatoria = Math.random() < 0.5 ? String.fromCharCode(65 + Math.floor(Math.random() * 26)) : String.fromCharCode(97 + Math.floor(Math.random() * 26));
        cadena += letraAleatoria;
      }
    }
  
    return cadena;
  }
  
  const generalLink = () =>{
    const res = validate()
    setSidebarOpen(false)
    if(res){
      setComponent(<CopyGeneralLink eventName={eventName}/>)
    }else{
      setComponent(<BuyMembership/>)
    }

    setTimeout(()=>{
      setOpenModal(true)
    },400)

}

const showPreview = ()=>{
  // cerrar nav
  window.open(process.env.REACT_APP_CLIENT + "/" + eventName, "_blank", "noreferrer") //to open new page
  // setSidebarOpen(false)
  // setPrev(true)
  // setComponent(<Router preview={eventName}/>)

  // setTimeout(()=>{
  //   setOpenModal(true)
  // },400)
}


const showHelp = ()=>{
  // cerrar nav
  setSidebarOpen(false)
  setPrev(true)
  setComponent(<Help/>)

  setTimeout(()=>{
    setOpenModal(true)
  },400)
}


  return (
    (
      <div>
      <Modal preview={prev} setOpen={setOpenModal} open={openModal} componetShow={componentDynamic}/>
       <button
              type="button"
              className=" px-4 text-white absolute top-5 focus:outline-none focus:ring-2 focus:ring-inset lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3CenterLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
       
        <div className="min-h-full">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 lg:hidden" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>
  
              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-cyan-700 pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                  {/*<div className="flex flex-shrink-0 items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                        alt="Easywire logo"
                      />
                    </div>*/}
                    <nav
                      className="mt-5 h-full flex-shrink-0 divide-y divide-cyan-800 overflow-y-auto"
                      aria-label="Sidebar"
                    >
                      <div className="space-y-1 px-2">
                      
                        {navigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-cyan-800 text-white'
                                : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))}
                        <p 
                        onClick={generalLink}
                        className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'>
                        <LinkIcon 
                        className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></LinkIcon>
                              Link de invitación general
                        </p>
                        <p 
                        role='link'
                        onClick={showPreview}
                        className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'>
                        <EyeIcon 
                        className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></EyeIcon>
                              Previsualizar página
                        </p>
                        <p 
                        onClick={showHelp}
                        className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'>
                        <EyeIcon 
                        className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></EyeIcon>
                              AYUDA
                        </p>
                      </div>
                    </nav>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
  
          {/* Static sidebar for desktop */}
          <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-grow flex-col overflow-y-auto bg-cyan-700 pt-5 pb-4">
              {/*<div className="flex flex-shrink-0 items-center px-4">
                <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=cyan&shade=300"
                  alt="Easywire logo"
                />
              </div>*/}
              <nav className="mt-5 flex flex-1 flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
                <div className="space-y-1 px-2">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-cyan-800 text-white' : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                      {item.name}
                    </a>
                  ))}
                  <p 
                  onClick={generalLink}
                  className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'>
                  <LinkIcon className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></LinkIcon>
                              Link de invitación general
                        </p>
                        <p 
                        role='link'
                        onClick={showPreview}
                        className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'>
                        <EyeIcon 
                        className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></EyeIcon>
                              Previsualizar página
                        </p>
                        <p 
                        onClick={showHelp}
                        className='text-cyan-100 cursor-pointer hover:text-white hover:bg-cyan-600 group flex items-center px-2 py-2 text-base font-medium rounded-md'>
                        <FireIcon 
                        className='mr-4 h-6 w-6 flex-shrink-0 text-cyan-200'></FireIcon>
                             AYUDA
                        </p>
                        
                </div>
                
              </nav>
            </div>
          </div>
  
          <div className="flex flex-1 flex-col lg:pl-64">
            
            <main className="flex-1 pb-8">
              
  
              <div className="mt-8">
                
              <Cards />
  <div className='mx-auto max-w-6xl px-4 sm:px-6 lg:px-8'>

                <button
                onClick={addList}
        type="button"
        className="relative my-3 block w-full border-2 border-gray-300 border-dashed rounded-lg  text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
                           
        <span className="mt-2 block text-sm font-medium text-gray-900"> + </span>
        <span className="mb-2 block text-sm font-medium text-gray-900"> Subir lista de invitados</span>
    </button>

    <button
        type="button"
        onClick={addGuest}
        className="relative my-3 w-full border-2 rounded-lg py-1 text-center bg-orange-300 hover:border-gray-400 focus:outline-none focus:ring-2 flex flex-row justify-center focus:ring-offset-2 focus:ring-orange-500">
        <UserIcon className='h-5 w-5  mr-1' />
        <span className="mb-2 block text-sm font-medium text-gray-900"> Subir invitad@</span>
    </button>
  </div>

  

               <GuestList validate={validate} setComponent={setComponent} setOpenModal={setOpenModal}/>



              </div>
            </main>
          </div>
        </div>
      </div>
    )
  )
}


