export const OPEN_MODAL = "OPEN_MODAL"
export const SAVE_PROGRESS = "SAVE_PROGRESS"
export const SET_ID = "SET_ID"
export const ADD_MICROEVENTS = "ADD_MICROEVENTS"
export const ADD_GIFTS = "ADD_GIFTS"
export const ADD_PHOTOS = "ADD_PHOTOS"
export const SET_MEMBERSHIP = "SET_MEMBERSHIP"
export const SET_GUEST_LIST = "SET_GUEST_LIST"
export const SET_TOTAL_CONFIRMED = "SET_TOTAL_CONFIRMED"
export const SET_USER = "SET_USER"
export const LOGOUT = "LOGOUT"
export const REFETCH = "REFETCH"
export const ADD_PHOTOS_MICROEVENTS = "ADD_PHOTOS_MICROEVENTS"

