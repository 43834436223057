import React from 'react'
import { Link } from 'react-router-dom'

export default function Prices() {
  return (
    <div id="precios"className="relative bg-white">
  <div className="absolute inset-0" aria-hidden="true">
    <div className="absolute inset-y-0 right-0 w-1/2 bg-gray-100"></div>
  </div>
  <div className="relative max-w-7xl mx-auto lg:px-8 lg:grid lg:grid-cols-2">
    <div className="bg-white py-16 px-4 sm:py-24 sm:px-6 lg:px-0 lg:pr-8">
      <div className="max-w-lg mx-auto lg:mx-0">
        
      <div className=" font-title max-w-2xl px-4 text-left ">
      <h2 className="text-3xl  tracking-tight text-cyan-800 sm:text-4xl">
        <span className="block">Precios de tu  <span className='font-spark text-4xl'>
        invitar.me
              </span></span>
        <span className="block"></span>
      </h2>
      <p className="mt-4 text-lg font-body font-light leading-6 text-gray-600">
            invitar.me es la invitación digital que necesitas
          </p>
      
      
    </div>


        <dl className="mt-12 space-y-10">
          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
          
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Historia de la pareja</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Contamos una breve historia de cuándo se conocieron, cuándo fue la primer cita, cuándo se hicieron novios y cuándo se comprometieron.
            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
                
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Cuenta regresiva</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Dile a tus invitados los días, horas y minutos que faltan para tu boda.
            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
        
                <svg className="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Fechas y ubicaciones</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Comparte las fechas importantes ( despedida, iglesia, salón) que tendrás así como el mapa con la dirección de la boda.
            </dd>
          </div>

          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
  
                <svg className="h-6 w-6 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Confirmación de invitados</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Controla tu evento con la cantidad de personas que asistirán solicitando Nombre, WhatsApp y número de asistentes.
            </dd>
          </div>
          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
     
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Mesa de regalos</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Incluye la información de la(s) mesa(s) de regalo que puedas tener para tu boda.
            </dd>
          </div>
          <div className="relative">
            <dt>
              <div className="absolute h-12 w-12 flex items-center justify-center verde rounded-md">
   
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
              </div>
              <p className="ml-16 text-lg leading-6 font-medium text-gray-600">Galeria de fotos</p>
            </dt>
            <dd className="mt-2 ml-16 text-base text-gray-500">
              Compartimos las mejores fotos de la sesión de novios que hayan tenido.
            </dd>
          </div>
        </dl>
      </div>
    </div>
    <div className="bg-stone-100 py-16 px-4 sm:py-24 sm:px-6 lg:bg-none lg:px-0 lg:pl-8 lg:flex lg:items-center lg:justify-end">
      <div className="max-w-lg mx-auto w-full space-y-8 lg:mx-0">
        <div>
          <h2 className="sr-only">Price</h2>
          <p className="relative ">
            <span className="flex flex-col text-center">
              <span className="text-5xl font-extrabold texto-gris tracking-tight">$3690</span>
              <span className="mt-2 text-base font-medium texto-gris">Precio base</span>
              
            </span>
            <span className="pointer-events-none absolute h-12 w-full flex items-center justify-center" aria-hidden="true">
  
            </span>
           
          </p>
        </div>
        
        <div className='flex items-center justify-center'>
        <Link to={"/createInvite/stepOne"}>
        <p
        className="mt-8 font-body font-semibold inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-300 px-5 py-3 text-base  text-cyan-800 hover:bg-orange-400 sm:w-auto"
        >
        Crear tu invitación
        </p>
        </Link>
        </div>
        
    
      </div>
    </div>
  </div>
</div>
  )
}
