import React, { useContext, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Context from '../../Context/Context';
import PhotoUpload from '../microComponents/PhotoUpload';
import { setToken } from '../../helpers';

export default function FormPhotos() {

  const hiddenFileInput = useRef();
  const [currentPhotoName , setCurrentPhotoName] = useState()
  const [loading , setLoading] = useState(false)
  const mensajes = [
  "Cargando las fotos" , 
  "Todos nuestros duendes estan trabajando" , 
  "Sólo un poco más" , 
  "Esa ultima foto esta pesada" , 
  "Verde, blanco y rojo.. ¿Pensaste en México?",
  "Ahora si, estamos por terminar",
  "Me encantó como salieron",
  "Un duende se quedo viendo la foto",
  "Parangaricuritimicuaro , esto tomo más de lo esperado",
  "No quiero ilusionarte, pero esto ya esta casi listo",
  "No eres tu , soy yo ..",
  "Tengamos paciencia ",
  "Ya no se que decirte",
  "Esto es vergonzoso",
  "Blah blah 90% ?",
  "jujuju"
]
  const [message , setMessage] = useState(0)
  const [idInterval , setIdInterval] = useState()

  const {addPhotosContext , photos , idEvento, progress , setUser} = useContext(Context)

  const navigate = useNavigate()

    const addPhotos = (id) =>{
        hiddenFileInput.current.click();
        setCurrentPhotoName(id)
      }
    
      
      const handleUploadServices = (ev) =>{

          addPhotosContext({
            max: photos.filter(e=>e.type === currentPhotoName)[0].max , 
            type: currentPhotoName,
            photos : ev.target.files,
            blob: Object.values(ev.target.files).map(e=> URL.createObjectURL(e))
          })
      }
      
      const activateMessages = ( ) =>{

        let i = 0
        
        const idInt = setInterval(()=>setMessage(i++),3500)
        
        setIdInterval(idInt)
      }

  
    const uploadPhotos = async( )=>{

        const validationPhotos = photos.filter(e=> e.blob.length === 0)

        if(validationPhotos.length > 0 ){
          alert("Sube almenos una foto")
          
        }else{
          
          try{
            
            for(let j = 0 ; j< photos.length ; j++){
              
              setLoading(true)
              activateMessages()
              
              for(let i = 0 ; i < (photos[j].max >= photos[j].photos.length ? photos[j].photos.length : photos[j].max ) ; i++){
                const dataF = new FormData();
                dataF.append("files" , photos[j].photos[i])
                dataF.append('ref', 'api::event.event')    // collection name
                dataF.append('refId', `${idEvento}`)   // post ID
                dataF.append('field', `${photos[j].type}`)
                
                await fetch(process.env.REACT_APP_SERVER +'/api/upload', {
                  method: 'post',
                  body: dataF
                });

                
              }

  
            }
            navigate("/user/loadingwhile")
            clearInterval(idInterval)
            
            
            }catch(e){
              console.log(e)
          }
        }

        
      

    }

    



  return (
    <>
    <div className='bg-orange-50 h-max pb-20 '>

      <div className="flex min-h-full flex-col justify-center py-8 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
         
          <h2 className="mt-6 text-center font-title text-3xl font-bold tracking-tight text-cyan-900">Creando tu <span className='font-spark text-4xl'>
                invitación
                </span> </h2>
          <p className="text-center text-sm text-gray-600">
            <p className="font-body font-light text-stone-600 hover:text-stone-500">
              Último paso 
            </p>
          </p>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full px-6 sm:max-w-lg">
          <div className="bg-stone-50 py-10 px-4 shadow rounded-xl sm:px-10">



            {
              !loading ? 

        <div>
            <h3 className='font-title text-lg font-bold mb-4'>Añade tus fotografías</h3>
              <p className="font-body  text-md text-stone-600 hover:text-stone-500">
              Foto principal ( Máx 3 fotos)
            </p>
            <PhotoUpload addPhotos={addPhotos} handleUploadServices={handleUploadServices}  type={"mainPhoto"}  hiddenFileInput={hiddenFileInput} multiple={true} max={3}/>
          
          
          <p className="font-body  text-md text-stone-600 hover:text-stone-500">
              Foto de cuenta regresiva ( Máx 1 fotos)
            </p>
          <PhotoUpload addPhotos={addPhotos} handleUploadServices={handleUploadServices} type={"countdown"}  hiddenFileInput={hiddenFileInput} multiple={false}  max={1}/>
          
          
          <p className="font-body  text-md text-stone-600 hover:text-stone-500">
              Foto "Save the Date"  ( Máx 1 fotos)
            </p>
          <PhotoUpload addPhotos={addPhotos} handleUploadServices={handleUploadServices} type={"Thanks"}  hiddenFileInput={hiddenFileInput} multiple={false} max={1}/>

          
          <p className="font-body  text-md text-stone-600 hover:text-stone-500">
              Galería de fotos ( Máx 8 fotos)
            </p>
          <PhotoUpload addPhotos={addPhotos} handleUploadServices={handleUploadServices}  type={"Galery"} hiddenFileInput={hiddenFileInput} multiple={true} max={8}/>


        <p className="font-body  text-sm text-stone-600 hover:text-stone-500">
         Podrás cambiarlas, puedes seleccionar unas de muestra
        </p>

        </div>
              : 
              <div className='text-center'>
              <div className='flex justify-center'>

      <svg className="animate-spin my-6 -ml-1 mr-3 h-20 w-20 text-orange-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
              </div>
              <span className='mt-8 font-title text-md font-bold'>

      {mensajes[message]}
              </span>
    </div>

            }
            
        <form 
        className='font-body'>

      {
        !loading ?
        <button
        type="button"
        onClick={uploadPhotos}
        className="text-center mt-4 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300"
      >
        Subir fotos
      </button>
        :

        <button
        type="button"

        className="text-center mt-4 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-300 hover:bg-orange-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-300 cursor-not-allowed"
      >
        Cargando ...
      </button>
      }
            
            
        </form>
        </div>
        </div>  
      </div>
    </div>
    </>
  )
}
