import { BuildingLibraryIcon, EnvelopeIcon, GiftIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function RegistryElegance({sobre,bank,giftRegistry ,fromGift, extra, bgColor}) {

  console.log(extra)

    return (
        <div className={` mt-12 bg-[${bgColor ?? "white"}}]  font-serif `}>
        <div className="mx-auto max-w-7xl px-14 py-6 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block uppercase">Mesa de regalos</span>
            
          </h2>
          <p className="mx-auto mt-3 max-w-xl text-lg text-gray-500 sm:mt-4">
            {
              extra.giftRegistryText ?? "Agradecemos mucho todo su amor y apoyo al iniciar esta etapa de formar nuestro hogar."
            }
          
              </p>
          <div className={`mt-8 justify-center}`} >
            
            <div className={`flex ${extra.giftsArrange ?"flex-row" :"flex-col sm:flex-row"}  justify-evenly`}>
            {
              sobre &&
    
              <div className=' px-2 py-4 sm:w-64 rounded-xl'>
                  <EnvelopeIcon  className='h-20 text-gray-600 mx-auto'></EnvelopeIcon>
                  {
                    extra.giftsShowText !== undefined ?

                    extra.giftsShowText &&
                    
                    <p className='mt-4 text-lg font-semibold'>Sobre </p>

                    :

                    <p className='mt-4 text-lg font-semibold'>Sobre </p>
                  }
              </div>  
            } 
            
            {
              bank &&
              <div className=' px-2 py-4 sm:w-64 rounded-xl'>
                  <BuildingLibraryIcon className='h-16 mb-2 mx-auto text-gray-600' />
                  {
                    extra.giftsShowText !== undefined ?

                    extra.giftsShowText &&
                    
                    <span>

                    <p className='mt-4 text-lg font-semibold'>Transferencia </p>
                  <p >CLABE : {bank.clabe}</p>
                  <p>Nombre del banco : {bank.nameBank}</p>
                  <p>Beneficiario :{bank.beneficiary} </p>
                    </span>

                    :

                    <span>

                    <p className='mt-4 text-lg font-semibold'>Transferencia </p>
                  <p >CLABE : {bank.clabe}</p>
                  <p>Nombre del banco : {bank.nameBank}</p>
                  <p>Beneficiario :{bank.beneficiary} </p>
                    </span>
                  }
                  
              </div>
            }
            {
    
              giftRegistry &&
    
              <div className='px-2 py-4 sm:w-64 rounded-xl'>
              <GiftIcon className='h-16 mb-2 mx-auto text-gray-600'/>
                  <p className='mt-4 text-lg font-semibold'>Mesa de regalos : </p>
                  <p>Liverpool
                  </p>
                  {
                    fromGift &&
                    <span>
                    
                    <a target="_blank"  href={fromGift}> <p className="mt-3 text-base text-blue-500">Ver lista</p></a>
                    </span>
                  }

            
              </div>
            }
            
            </div>

            {
              extra.customRegistry &&

              extra.customRegistry.map(e=>{
                return <div className='w-full mx-auto mt-3 max-w-xl text-lg text-gray-500 sm:mt-4  '>
                <p className='mt-4 text-xl font-bold'>{e.title}</p>
                  {
                    e.content.map(c=>{
                      return <span className='flex flex-row justify-center'><p className='text-lg font-bold'>{c.title ? (c.title + ":") : null}  </p> <p className='text-lg mx-1'>{c.value}  </p></span>
                    })
                  }
                </div>
              })
            }
          </div>
        </div>
      </div>
      )
}
