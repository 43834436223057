import React from 'react'
import { useCountdown } from '../../Hooks/useCountdown';

export default function CountDown({date,photo}) {

    const [days, hours, minutes, seconds] = useCountdown(date);

  return (

    
    <section >

          <div className="relative  bg-gray-900 bg-opacity-75  px-6 sm:px-12 lg:px-16 mx-auto flex sm:pt-36 pt-24 pb-24 flex-col items-center text-center">
            <div className="absolute inset-0">
            {
              photo&&
              <img
              src={photo}
              alt=""
              className="h-full w-full object-cover object-center"
              />
            }
            </div>
              <div >
                <h2 id="social-impact-heading" className="text-5xl text-white sm:text-6xl">
                  <span className="block sm:inline font-cursivemodern ">Cuenta regresiva</span>
                </h2>
                <div className="relative flex-row text-center sm:pb-48 grid grid-cols-4 px-8  top-12 text-xl sm:text-3xl">
    
                    <div className='text-center text-white justify-center'>
                        <div className='bg-rose-300 mx-auto h-14 w-14 rounded-full sm:h-24 sm:w-24'>
                            <p className='relative top-3 sm:top-7'>{days}</p>
                        </div>
                        <p className='top-2 relative uppercase tracking-wide text-xs sm:text-lg'>Dias</p>
                    </div>
    
                    <div className='text-center text-white justify-center'>
                        <div className='bg-rose-300 mx-auto h-14 w-14 rounded-full sm:h-24 sm:w-24'>
                            <p className='relative top-3 sm:top-7'>{hours}</p>
                        </div>
                        <p className='top-2 relative uppercase tracking-wide text-xs sm:text-lg'>Horas</p>
                    </div>
    
                    <div className='text-center text-white justify-center'>
                        <div className='bg-rose-300 mx-auto h-14 w-14 rounded-full sm:h-24 sm:w-24'>
                            <p className='relative top-3 sm:top-7'>{minutes}</p>
                        </div>
                        <p className='top-2 relative uppercase tracking-wide text-xs sm:text-lg'>Minutos</p>
                    </div>
    
                    <div className='text-center text-white justify-center'>
                        <div className='bg-rose-300 mx-auto h-14 w-14 rounded-full sm:h-24 sm:w-24'>
                            <p className='relative top-3 sm:top-7'>{seconds}</p>
                        </div>
                        <p className='top-2 relative uppercase tracking-wide text-xs sm:text-lg'>Segundos</p>
                    </div>
    
                </div>
            </div>

            
          </div>

        </section>
  )
}
