import { Dialog, RadioGroup } from '@headlessui/react'
import { useState } from 'react'


export default function BuyMembership() {
    const plans = [
        { name: 'Full Inviter', ram: 'Confirmación de invitados', cpus: 'Invitación digital', disk: 'Selección de diseño', price: '1,299'  , other :"Invitación personalizada por invitado"}
      ]
      
      function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
      }
    const [selected, setSelected] = useState(plans[0])
 
    return (
    <>

    <div>
                      
                      <div className="mt-3 text-left sm:mt-5 mb-4">
                        <Dialog.Title as="h3" className="text-lg font-bold leading-6 text-gray-900">
                          Desbloquea todas las funciones
                        </Dialog.Title>
                        <p className='font-light'>y ten el control de tus invitados</p>
                      </div>
                    </div>
                    <RadioGroup value={selected} onChange={setSelected}>
      <RadioGroup.Label className="sr-only"> Server size </RadioGroup.Label>
      <div className="space-y-4">
        {plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className={({ checked, active }) =>
              classNames(
                checked ? 'border-transparent' : 'border-gray-300',
                active ? 'border-orange-300 ring-2 ring-orange-300' : '',
                'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center">
                  <span className="flex flex-col text-md">
                    <RadioGroup.Label as="span" className="text-lg font-bold font-title text-gray-900">
                      {plan.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className="text-gray-500">
                      <span className="block sm:inline">
                       - {plan.ram}
                      </span>
                      <span className=" sm:mx-1 sm:inline" aria-hidden="true">
                       - {plan.cpus}
                      </span>
                      <span className="block sm:inline"> - {plan.disk}</span>
                      <span className="block sm:inline"> - {plan.other}</span>
                    </RadioGroup.Description>
                  </span>
                </span>
                <RadioGroup.Description
                  as="span"
                  className="mt-2 flex text-red-500 text-sm sm:mt-0 sm:ml-4 sm:flex-col sm:text-right"
                >
                  <span className="font-bold text-lg">{plan.price}</span>
                  <span className="ml-1  sm:ml-0">mxn</span>
                </RadioGroup.Description>
                <span
                  className={classNames(
                    active ? 'border' : 'border-2',
                    checked ? 'border-orange-300' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg'
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm"
                        // onClick={() => setOpen(false)}
                      >
                        Comprar
                      </button>
                    </div>
    </>
  )
}
