import React from 'react'

export default function Thanks({photo}) {
  return (
    <section>
    <div className="relative overflow-hidden ">
      <div className="absolute inset-0">
        <img
          src={photo}
          alt=""
          className="h-full w-full object-cover object-center"
        />
      </div>
      <div className="relative bg-gray-900 bg-opacity-75 py-32 px-6 sm:py-40 sm:px-12 lg:px-16">

        <div className="relative mx-auto flex  flex-col items-center text-center">
          <h2 id="social-impact-heading" className="text-5xl text-white sm:text-6xl">
            <span className="block sm:inline font-cursivemodern ">Gracias</span>
          </h2>
          
          <p className="mt-4 uppercase tracking-wide text-lg text-white">
            ¡ Gracias por acompañarnos!
          </p>
          
        </div>
      </div>
    </div>
  </section>
  )
}
