import React from 'react'

export default function Parents() {
  return (
    <div >
    <div className="font-serif uppercase w-3/4 mx-auto ">
      
      
        <div className="text-center ">
          <h2 className="text-2xl uppercase font-bold tracking-tight text-gray-900 sm:text-2xl mt-10">Nuestros padres</h2>
         
          <div className="flex flex-row justify-center items-center sm:mt-4 mt-8">
  <p className="text-lg text-gray-500">
    Félix Jaime Rodríguez  
  </p>
  <img className="h-4 mx-1" src="https://pilcrow.mx/cruz.png" alt="Cruz" />
</div>


          <p className="mx-auto mt-1 text-lg text-gray-500 sm:mt-4">
María Guadalupe Martínez Quintanilla

          </p>

          <p className="mx-auto text-lg my-2 text-gray-500 sm:mt-4">
y
          </p>

          <p className="mx-auto  text-lg text-gray-500 sm:mt-4">
          Marco Antonio Valverde Álvarez

          </p>
          <p className="mx-auto mt-1  text-lg text-gray-500 sm:mt-4">
          Martha Graciela Salinas Santos

          </p>

        </div>
        
      </div>
    </div>
  )
}
