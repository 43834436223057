/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Modal from '../Dashboard/Modal'
import ConfirmGuestClient from '../InvitesComponents/ConfirmGuestClient'
import CountDown from '../InvitesComponents/CountDown'
import Galery from '../InvitesComponents/Galery'
import HeroCarousel from '../InvitesComponents/HeroCarousel'
import MicroEvents from '../InvitesComponents/MicroEvents'
import Registry from '../InvitesComponents/Registry'
import SaveTheDate from '../InvitesComponents/SaveTheDate'
import Thanks from '../InvitesComponents/Thanks'
import BannerInviteName from '../InvitesComponents/BannerInviteName'

export default function Modern({guest , event , preview}) {

  // console.log("MERCDA MODERNA", event )
    const [open, setOpen] = useState(false)

    const guestInfo = {
        id:guest.id,
        attributes:{
            maxGuests : guest.maxGuests,
            nameInvitation : guest.nameInvitation
        }
    }


  return (
    <div>
        {/* hero - falta el mensaje de abajo*/}
        <HeroCarousel preview={preview} photo={event.mainPhoto} groom={event.nameGroom} bride={event.nameBride}/>

        {/* BANNER NOMBRE*/}
        {
          guest.id &&
          <BannerInviteName guest={guest}></BannerInviteName>
        }

        {/* microeventos */}
        <MicroEvents events={event.microEvents} 
        placesPhotos={[
          {name :"Boda Religiosa", image :event.religiousPhoto} , 
          {name :"Recepción", image : event.venuePhoto} ,
          {name :"Despedida de soltera", image : event.bacheloretesPhoto}]
          } />

        {/* Confirmación */}
        <Modal open={open} setOpen={setOpen}  componetShow={<ConfirmGuestClient event={event} guest={guest} data={guestInfo} setOpen={setOpen} color={"bg-rose-300"}/>} />

        {/* Save the Date */}
        <SaveTheDate date={event.date} setOpen={setOpen}/>
        
        {/* Mesa de Regalos */}
        <Registry sobre={event.sobre} bank={event.bankAccount} giftRegistry={event.giftRegistry}/>
        
        {/* CountDown */}
        <CountDown date={event.date} photo={event.countDownPhoto}></CountDown>

        {/* Galeria */}
        <Galery gallery={event.galery}/>
        
        {/* Gracias  */}
        <Thanks photo={event.thanksPhoto} />
    </div>
  )
}
