import React from 'react'

export default function BannerSweet({guest}) {


  return (
    <div className="z-50 text-center bg-rose-300 font-raleway py-4">
          <h2 className="text-2xl  text-rose-50 sm:text-2xl">
          <span className="relative">
  <h2 className="text-2xl   text-rose-50 sm:text-2xl">{guest.nameInvitation}</h2>
    {/* <span
      className="{` absolute -bottom-0 -left-26 -top-1 inline-block bg-rose-300 w-full animate-type will-change`}"
    ></span> */}
  </span>
          </h2>
          <p className="mx-auto  max-w-xl text-sm text-white sm:mt-4">
            Recibe la más cordial invitación a mis xv años
          </p>
        </div>
  )
}
