import React from 'react'

export default function Examples() {
  return (
    <div className="bg-white">

  <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
    <div className="space-y-2">

    <div className=" font-title max-w-2xl py-16 px-4 text-left sm:py-20 sm:px-6 lg:px-8">
    <h2 className="text-3xl  tracking-tight text-cyan-800 sm:text-4xl">
      <span className="block">Algunos ejemplos de <span className='font-spark text-4xl'>
            invitación
            </span></span>
      <span className="block"></span>
    </h2>
    <p className="mt-4 text-lg font-body font-light leading-6 text-gray-600">
          invitar.me es la invitación digital que necesitas
        </p>
    
    
  </div>



      <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/invitacion-digital.png" alt=""/>
            </div>

            
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/boda-online-monterrey.png" alt=""/>
            </div>

            
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/invitacion-web-monterrey.png" alt=""/>
            </div>

            
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/boda-invitacion.png" alt=""/>
            </div>

            
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/boda-web.png" alt=""/>
            </div>

            
          </div>
        </li>
        <li>
          <div className="space-y-4">
            <div className="aspect-w-3 aspect-h-2">
              <img className="object-cover shadow-lg rounded-lg" src="https://pilcrow.mx/img/boda-branding.png" alt=""/>
            </div>

            
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
  )
}
