import React from 'react'

export default function InputShort({title , handleChange, name , type , required , value}) {

  return (
    <>
     
                <div className="mt-1 font-body ">
                  <input
                    onChange={handleChange}
                    id={name}
                    name={name}
                    type={type}
                    required={required}
                    value={value}
                    className="text-md sm:text-lg block w-full appearance-none rounded-md border border-gray-300 px-3 py-1 placeholder-stone-400 shadow-sm focus:border-stone-300 focus:outline-none focus:ring-stone-500"
                    placeholder={title}

                  />
                </div>
    </>
  )
}
