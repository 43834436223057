import React, { useContext, useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import downloadExcel from '../../Hooks/downloadExcel'
import FileUpload from './FileUpload'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import Context from '../../Context/Context'

export default function AddList() {

  const [downloadExcelPricesSpinner, setDownloadExcelPricesSpinner] = useState(false)
  const[dataExcel , setDataExcel] = useState()
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = useRef();
  const [activateSend, setActivate ] = useState(false)
  const [errorSe, setError ] = useState({message:"" , on:false})
  const [loadingS, setLoading ] = useState(false)

  const navigate = useNavigate()


  const handleUploadServices = (ev) =>{
    setFileName(ev.target.value.replace("C:\\fakepath",''))
    handleExcel(ev)
}

const {id} = useParams()
const {setRefetchLo , refetchLo} = useContext(Context)


  const handleExcel = async (ev)=>{
    
    if(ev.target.files[0] == undefined){
      return
    }

    let result 
    let reader = new FileReader()
    reader.readAsArrayBuffer(ev.target.files[0])
    
    reader.onloadend = (e)=>{


          let data = new Uint8Array(e.target.result)
         
          let workbook = XLSX.read(data, {type: 'array'})
          
          let resultado = {};
          let allSheets = [];
          
          workbook.SheetNames.forEach(sheetName => {
            allSheets.push(sheetName) 
          })
          
          let worksheet = workbook.Sheets[allSheets[0]];
          resultado[allSheets[0]] = XLSX.utils.sheet_to_json(worksheet, { raw: true });
          
          result = resultado[allSheets[0]];
    
          if(result.length){

            if(result[0].NOMBRE_INVITACIÓN !== undefined && result[0].CANTIDAD_INVITADOS !== undefined){
              setActivate(true)
              setDataExcel(result);
              setError({message:"" , on:false})
            }else{
              setError({message:"Formato incorrecto , revisa el documento que no haya espacios en blanco o letras en vez de numeros" , on:true})
            }
          }else{
            setError({message:"Formato incorrecto , revisa el documento que no haya espacios en blanco o letras en vez de numeros" , on:true})
            }
          }
        
          
        }
        

  const sendToback = async ()=>{

    setLoading(true)
    let res = await axios.post(process.env.REACT_APP_SERVER + `/api/uploadExcel` , {
      data: {
        guests: dataExcel,
        evento: id
      }
    }) 

 

    if(res.data == 200){
      setRefetchLo(!refetchLo)
      setLoading(false)
      navigate(`/user/dashboard`)

      // redirect to dashboard
    }else if(res.data == 400){
      setLoading(false)
      setError({message:"Error, intenta de nuevo o llama a atención a clientes" , on:true})
    }

  }

  const download = ()=>{
    return (
      downloadExcel.downloadAsExcel({excel: [
        {
                                           NOMBRE_INVITACIÓN: "Familia Gonzalez",
                                           CANTIDAD_INVITADOS: 4,
                                          }     
                                        ], nameFile: "Invitados_Boda"
                                      })
                                    )
  }

  const addFile = (id) =>{
    hiddenFileInput.current.click();
  }

  return (
    <div className="text-center sm:w-1/2 flex flex-col justify-center mx-auto my-12 space-y-5 p-12 shadow-lg rounded-md ">
    <p className='text-xl'>Paso 1)</p>
    <p className='text-blue-500 cursor-pointer text-center text-xl ' onClick={download}>
    Descargar formato de excel
    </p>

    

    <div className="col-span-6 sm:col-span-6 mb-2">
    
    
    <span className='mb-6'>
    <p className='text-xl'>Paso 2)</p>
    <p className='text-md ' onClick={download}>
    Llena los invitados y los pases
    </p>
    </span>

   

    <p className='text-xl mt-6'> Paso 3)</p>
    <FileUpload addPhotos={addFile} handleUploadServices={handleUploadServices} hiddenFileInput={hiddenFileInput} fileName={fileName}></FileUpload>

    {
      errorSe.on &&
      <div className="text-left rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">Error</h3>
          <div className="mt-2 text-sm text-red-700">
            <p>{errorSe.message} </p>
          </div>
          
        </div>
      </div>
    </div>
    }
                            { fileName !== "" &&
                                ( <p className='text-gray-500 font-medium'>Archivo cargado: <span className='font-normal'>{fileName}</span> </p> )
                            }
                            
                        </div>




                        
                        {
                          
                          activateSend ?

                          loadingS ?

                          <button
                          className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm  mt-2"
                                              
                                            >
                                              Cargando...
                                            </button>
                          :
                          <button
    type="button"
    onClick={sendToback}
    className="inline-flex w-full justify-center rounded-md border border-transparent bg-orange-300 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm  mt-2"
                        
                      >
                        Cargar lista
                      </button>

                      : null
                          
                        }
    </div>
  )
}
