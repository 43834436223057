import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { UPDATE_GUEST } from '../../GraphQL/Mutations'
import DropDown from '../microComponents/DropDown'
import { useLocation } from 'react-router-dom'

export default function ConfirmGuest({data , setOpenModal , color , font , textColor}) {
  
    const [number , setNumber]= useState(1)
    const [alert , setAlert] = useState(false)
    const {pathname} = useLocation() 

    const maxNumber = data.attributes.maxGuests
    const arrNumbers = []
    
    for(let i = 1; i<= maxNumber ; i++){
      arrNumbers.push(i)
    }

    const succes = () =>{
      setAlert(true)
      setTimeout(()=>{
          setOpenModal(false)
      },500)
    }

   

    const confirmButton = () =>{
      updateGuest({
        variables:{
          confirmed:Number (number),
          id:data.id,
          status:pathname == "/user/dashboard" ?  "confirmadoManual" : "confirmadoWeb" 
        }
      })
    }

    const handleChange = (number) =>{
      setNumber(number.target.value)
    }

    const [updateGuest] = useMutation(UPDATE_GUEST , {
      onCompleted : () => succes()
    })

    return (
    <div>
     <div className={` pb-12 ${font ? font : " font-body"} `}>
      <div>

      <label htmlFor="search" className="block text-lg font-bold text-gray-700">
        Confirmar asistencia de :
      </label>
      <div className='grid grid-cols-2 my-8'>

      <p className='text-md '>{data.attributes.nameInvitation} : </p>

     <DropDown id={"1"} name={"confirmed"} defaultValue={1} options={arrNumbers} handleChange={handleChange} ></DropDown>
      
      </div>

      {
        alert &&
      <div className='my-3'> 
      <p className={`text-sm ${color ? color : "bg-orange-300"} text-center text-black rounded-md py-2`}> Invitado confirmado </p>
      </div>
      }
      
      <button
                        type="button"
                        className={`inline-flex font-semibold w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium ${textColor ? textColor : "text-black"} shadow-smsm:text-sm ${color ? color : "bg-orange-300"} `}
                        onClick={() => confirmButton()}
                      >
                        Confirmar invitado
                      </button>
    </div>
    </div>
    </div>
  )
}
