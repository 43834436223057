import { gql } from "@apollo/client"

export const LOGIN_USER = gql`
mutation($user:String! $password:String!){
  login(input:{identifier:$user password:$password}){
		jwt
    user{
      id
      email
      username
    	events{
        data{
          id
          attributes{
            type
            eventName
          }
        }
      }
    }
  }
}
`

export const UPDATE_EVENT_DATES = gql`
mutation updateMicroEvent(
  $micro:[ComponentMicroMicroEventsInput]!
  $id:ID!
  $sobre:Boolean
  $bankDet:ComponentEvBankInput
  $giftRegistry:String
  $giftFrom:String
) {
  updateEvent(
    id:$id , 
    data: {
      microEvents :$micro
      sobre:$sobre
      bankAccount:$bankDet
      giftsRegistry:$giftRegistry,
      fromGift:$giftFrom,
      step:datesCompleted
    }
  ){
    data {
      id
    }
  }
}
`


export const CREATE_EVENT_BASIC_INFO = gql`
mutation createEvent(
    $eventName:String!
  $nameBride: String!
   $nameGroom: String!
      $principal: String!
      $pareja:String!
      $weddingPlanner: String
      $date:Date!
  ){
    createEvent(data:{
      eventName:$eventName
      nameBride:$nameBride
      nameGroom:$nameGroom
      principal:$principal
      pareja:$pareja
      weddingPlanner:$weddingPlanner
      date:$date
      step:basicInfo
    }){
      data{
        id
        attributes{
          eventName
          nameBride
          nameGroom
          principal
          pareja
          weddingPlanner
          date
        }
      }
    }
  }
`

export const ADD_PHOTOS = gql`
mutation uploadPhotosStrapi(
  $mainPhoto:[ID]
  $countD:ID
  $thanks:ID
  $galery:[ID]
  $id:ID!
) {
  updateEvent(
    id:$id , 
    data: {
      mainPhoto:$mainPhoto
      countdown:$countD
      Thanks:$thanks
      Galery:$galery
    }
  ){
    data {
      attributes{
        date
      }
    }
  }
}
`

export const PRE_LOAD_PHOTOS = gql`
mutation uploadPhotosStrapi(
  $file:Upload!
  $ref:String!
  $refID:ID!
  $field:String!
){
  upload(
    file:$file
    ref:$ref
    field:$field
    refId:$refID
  ){
    data{
      attributes{
        url
      }
    }
  }
}
`

export const POST_GUEST = gql`
mutation($guest:String!, $maxGuest:Int! ,$id:ID! ){
  createGuest(data:{
    maxGuests:$maxGuest,
    event:$id,
    nameInvitation: $guest
  }){
    data{
      id
      attributes{
        nameInvitation
        maxGuests
        url
      }
    }
  }
}
`

export const GENERAL_GUEST = gql`
mutation($guest:String!, $confirmed:Int! ,$id:ID! ){
  createGuest(data:{
    confirmed:$confirmed,
    event:$id,
    nameInvitation: $guest,
    status:confirmadoGeneral
  }){
    data{
      id
      attributes{
        nameInvitation
        maxGuests
        url
      }
    }
  }
}
`


export const UPDATE_GUEST = gql`
mutation updateGuest($confirmed:Int! ,$id:ID! , $status:ENUM_GUEST_STATUS){
  updateGuest(id:$id , data:{ confirmed:$confirmed , status:$status}){
    data{
      id
      attributes{
        nameInvitation
        maxGuests
        status
      }
    }
  }
}
`
export const OPEN_INVITE = gql`
mutation openInvite($id:ID!){
  updateGuest(id:$id , data:{opened:true}){
    data{
      id
    }
  }
}
`




