import { ChevronDownIcon } from '@heroicons/react/24/outline'
import React from 'react'

export default function HeroCarousel({groom, bride , photo , preview}) {
  return (
    
        <div className='h-screen grid content-center' >
        
                <div className="absolute inset-0">
                  <img
                    className={`${preview ? "  h-1/6 ": "h-full"} w-full object-cover`}
                    src={photo}
                    alt="People working on laptops"
                  />
                </div>

                <div className="relative  ">
                  <h1 className="text-center text-7xl sm:text-9xl">
                    <span className="block font-cursivemodern text-white">{groom}</span>
                  </h1>
                  <h1 className="text-center my-2">
                  <img src='https://res.cloudinary.com/dxweypnlj/image/upload/v1668278491/and-banner_lbsqad.png' 
                  className='absolute w-14 mx-auto left-4 right-0'
                  alt='curve'/>
                    <span className="block text-3xl font-cursivemodern text-white">and</span>
                  </h1>
                  <h1 className="text-center text-7xl sm:text-9xl ">
                    <span className="block font-cursivemodern text-white">{bride}</span>
                  </h1>
                  <p className="font-raleway tracking-widest mx-auto mt-6 font-semibold  text-center text-xs sm:text-lg text-white ">
                    WE ARE GETTING MARRIED
                  </p>
                </div>
             
                <span className='relative text-white w-12 mx-auto animate-bounce top-24 '>
                  <ChevronDownIcon />
                </span>
        </div>
   
  )
}
