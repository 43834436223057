 import React from 'react'
 
 export default function DressCode() {


    const people =[
        {
            name : "Hombres" , 
            descri:"Formal casual"  ,
            imageUrl : "https://media.revistagq.com/photos/5ca5fc2033e7510376153a8b/master/w_1600%2Cc_limit/dress_code_casual_chic_gq_4834.jpg"
        },
        {
            name : "Mujeres" , 
            descri:"Formal casual ( NO COLOR AZUL NI ROSA) " ,
            imageUrl : "https://res.cloudinary.com/dxweypnlj/image/upload/v1720746195/Captura_de_pantalla_2024_07_11_a_las_19_01_51_9f09dea72d.png?updated_at=2024-07-12T01:03:16.102Z"
        }
        
    ]

   return (
     <div>
        <div className="flex justify-center">
            <img
              alt=""
              src="https://nicolexv.pilcrow.mx/assets/images/sec-title-flower.png"
              className="w-32"
            />
          </div>
          <div className="mx-auto max-w-2xl px-4  sm:px-6 lg:max-w-7xl lg:px-8">

          <div className="text-center">
          <h2 className="text-2xl uppercase font-semibold raleway tracking-tight text-gray-900 sm:text-4xl">Codigo de vestimenta:</h2>
          {/* <p className="sm:text-lg text-md text-center  text-gray-500 ">For</p>  */}


          <ul
          role="list"
          className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 "
        >
          {people.map((person) => (
            <li key={person.name}>
              <img alt="" src={person.imageUrl} className="aspect-[3/2] w-full rounded-2xl object-cover" />
              <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3>
              <p className="text-base leading-7 text-gray-600">{person.descri}</p>
              
            </li>
          ))}
        </ul>

        </div>
        </div>


     </div>
   )
 }
 