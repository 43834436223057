import { Routes, Route, useLocation, Link } from "react-router-dom";
import AddList from "./Components/Dashboard/AddList";
import Dashboard from "./Components/Dashboard/Dashboard";
import Form from "./Components/Landing/Form";
import FormEventDetails from "./Components/Landing/FormEventDetails";
import FormPhotos from "./Components/Landing/FormPhotos";
import MainPage from "./Components/Landing/MainPage";
import Navbar from "./Components/Navbar";
import Router from "./Components/Templates/Router";
import Modern from "./Components/Templates/Modern";
import Login from "./Components/Landing/Login";
import LoaderWhileLoggin from "./Components/Landing/LoaderWhileLoggin";

function App() {

const {pathname} = useLocation()

  return (
    <div className="overflow-hidden">
    {

["/createInvite/stepOne", "/createInvite/stepTwo", "/createInvite/stepThree", "/user/login" ,"/user/loadingwhile" ,  "/addGuestList" , "/"].includes(pathname) &&
      <Navbar/>
    }
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/createInvite/stepOne" element={<Form />} />
      <Route path="/createInvite/stepTwo" element={<FormEventDetails/>} />
      <Route path="/createInvite/stepThree" element={<FormPhotos/>} />
      <Route path="/user/dashboard" element={<Dashboard/>}/>
      <Route path="/addGuestList/:id" element={<AddList/>}/>
      <Route path="/:url/:fam" element={<Router/>}/>
      <Route path="/:url" element={<Router/>}/>
      <Route path="/user/login" element={<Login/>} />
      <Route path="/user/loadingwhile" element={<LoaderWhileLoggin/>} />
    </Routes>
    <div className="border-t border-gray-900/10 ">
    
    <p className=" p-3 text-xs leading-5 text-gray-500">&copy; 2024 <Link to={"/"}>Inviter</Link>, hecho en MTY.</p>
  </div>
    </div>
  );
}

export default App;
