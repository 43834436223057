import React from 'react'
const products = [
    {
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/home-page-04-trending-product-02.jpg',
      imageAlt: 'Hand stitched, orange leather long wallet.',
    },
  ]

export default function Galery({gallery , bgColor}) {
  // console.log(gallery)
    return (
        <div className={` mt-12 bg-[${bgColor ?? "white"}}]  font-serif my-2`}>
          <div className="mx-auto max-w-2xl py-2 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">

          <div className="text-center">
          {/* <h2 className="text-2xl uppercase font-bold tracking-tight text-gray-900 sm:text-2xl">Nuestras fotos</h2> */}
        </div>
    
            <div className="mt-6 grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-0 lg:gap-x-8">
              {gallery.map((product , id) => (
                <div key={id} className="group relative">
                  <div className="h-56 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:h-72 xl:h-80">
                    <img
                      src={product.url}
                      alt={product.url}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
  )
}
