import React from 'react'
import Cta from './Cta'
import Features from './Features'
import Hero from './Hero'
import Examples from './Examples'
import Prices from './Prices'
import Helpy from './Helpy'

export default function MainPage() {
  return (
    <>
      <Hero></Hero>
      <Cta></Cta>
      <Examples/>
      <Prices></Prices>
      <Features></Features>
      <Helpy></Helpy>
    </>
  )
}
